import { Box, Card, CardContent, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import palette from '../../../../theme/palette'
import { indianFormatNumber } from '../../../../components/functions/data_functions'

const TransactionStats = ({ data }) => {

    const isMobileScreen = useMediaQuery('(max-width:475px)');

    return (
        <Grid container spacing={isMobileScreen ? 1 : 2} marginBottom={'20px'}>
            <Grid item xs={6} sm={3} >
                <Card sx={{ minWidth: 'auto', background: '#00BF63', color: '#ffffff' }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}>
                            {data.receipt_total ? indianFormatNumber(data.receipt_total) : '0.00'}
                        </Typography>
                        <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}>
                            Net Receipts
                        </Typography>

                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={3} >
                <Card sx={{ minWidth: 'auto', background: '#FA9B4D', color: '#ffffff' }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}>
                            {data.payment_total ? indianFormatNumber(data.payment_total) : '0.00'}
                        </Typography>
                        <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}>
                            Net Payment
                        </Typography>

                    </CardContent>
                </Card>
            </Grid>
            {
                data?.opening_balance ?
                    <Grid item xs={6} sm={3} >
                        <Card sx={{ minWidth: 'auto', background: '#38B6FF', color: '#ffffff' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}>
                                    {data.opening_balance ? indianFormatNumber(data.opening_balance) : '0.00'}
                                </Typography>
                                <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}>
                                    Opening Balance
                                </Typography>

                            </CardContent>
                        </Card>
                    </Grid> : null
            }
            {
                data?.closing_balance ?
                    <Grid item xs={6} sm={3}  >
                        <Card sx={{ minWidth: 'auto', background: '#DE58FF', color: '#ffffff' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}>
                                    {data.closing_balance ? indianFormatNumber(data.closing_balance) : '0.00'}
                                </Typography>
                                <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}>
                                    Closing Balance
                                </Typography>

                            </CardContent>
                        </Card>
                    </Grid> : null
            }
        </Grid >

    )
}

export default TransactionStats