import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { Add, Delete, ExpandMore } from '@mui/icons-material';
import CustomAddButton from '../../../components/customAddButton/CustomAddButton';
import { apiCall, handleSuccessSubmit } from '../../../components/functions/apiCall';
import {
  API_SIGN,
  FETCH_STOCK,
  FETCH_TRANSACTION_LEDGER,
  FETCH_TRANSACTION_SALES,
  LAST_BILLING_SHIPPING_DATA,
  LAST_COMPANY_DATA,
  SALES_TRANSACTION,
  SPECIFIC_COMPANY,
} from '../../../variables/api-variables';
import { decryptToken } from '../../../components/security/securityFunctions';
import palette from '../../../theme/palette';
import DialogGSTDiscount from './dialog/DialogGSTDiscount';
import DialogGSTDiscountAll from './dialog/DialogGSTDiscountAll';

const AddBill = ({ transactionId }) => {
  console.log(transactionId);
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const company = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}').company.company_id;
  console.log(company);
  const [lastFormValues, setLastFormValues] = useState();
  const [formValues, setFormValues] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreate, setIsCreate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [gstDiscountDialog, setGstDiscountDialog] = useState(false);
  const [gstDiscountDialogAllOpen, setGstDiscountDialogAllOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(urlSearchParams.has('page') ? parseInt(urlSearchParams.get('page'), 10) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [stockDetails, setStockDetails] = useState([]);
  const [ledgerDetails, setLedgerDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  const [lastCompanyData, setLastCompanyData] = useState({});
  const [lastBillingShippingData, setLastBillingShippingData] = useState([]);
  const [ledgerName, setLedgerName] = useState([]);

  const searchParams = new URLSearchParams(location.search);
  let companyId = searchParams.get('company_id');
  if (userToken.workflow_type === 'individual') {
    if (!companyId || companyId === '') {
      companyId = company;
    }
  }

  const cellStyle = {
    padding: 3,
  };

  const fetchTransactionSales = () => {
    setIsLoading(true);
    try {
      const inputData = JSON.stringify({
        function_type: 'fetch_transaction_sales',
        company_id: companyId || company,
        transaction_id: transactionId,
        jwt_token: userToken,
        api_signature: API_SIGN,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_TRANSACTION_SALES, 'plain/text')
        .then((response) => {
          setIsLoading(false);
          console.log('Sales transaction: ', response);
          if (response.data.status === 1) {
            const transactionInfo = response.data.response.transaction_info.transactiondata;
            const stockInfo = response.data.response.transaction_info.stock_info;
            const totalGstDiscount = response.data.response.transaction_info.total_gst_discount;

            // Map the response to form values
            setFormValues({
              date: transactionInfo.date,
              ledgername: transactionInfo.company_details.company_name,
              transactiontype: transactionInfo.transactiontype || 'sales',
              narration: transactionInfo.remarks || '',
              my_company_details: {
                company_name: transactionInfo.company_details.company_name,
                company_address: transactionInfo.company_details.company_address,
                email: transactionInfo.company_details.email,
                mobile: transactionInfo.company_details.mobile,
                gst: transactionInfo.company_details.gst,
              },
              billing_shipping_same: 0,
              billing_address: {
                company_name: transactionInfo.billing_address.company_name,
                company_address: transactionInfo.billing_address.company_address,
                email: transactionInfo.billing_address.email,
                mobile: transactionInfo.billing_address.mobile,
                gst: transactionInfo.billing_address.gst,
              },
              shipping_address: {
                company_name: transactionInfo.shipping_address.company_name,
                company_address: transactionInfo.shipping_address.company_address,
                email: transactionInfo.shipping_address.email,
                mobile: transactionInfo.shipping_address.mobile,
                gst: transactionInfo.shipping_address.gst,
              },
              total_gst_discount: [
                {
                  discount: totalGstDiscount.discount || {},
                  gst: {
                    cgst: totalGstDiscount.gst?.cgst || {},
                    sgst: totalGstDiscount.gst?.sgst || {},
                    igst: totalGstDiscount.gst?.igst || {},
                    cess: totalGstDiscount.gst?.cess || {},
                  },
                  other_tax: totalGstDiscount.other_tax || {},
                },
              ],
              stock_info: stockInfo.map((stock) => ({
                stock_name: stock.stock_name,
                hsn_code: stock.hsn_code,
                qty: stock.current_qty || '1',
                mrp: stock.stock_transactions?.mrp || '',
                discount: stock.stock_transactions?.discount || {},
                gst: stock.stock_transactions?.gst || {},
                other_tax: stock.stock_transactions?.other_tax || {},
                total_amount: stock.stock_transactions?.amount || '0.00',
              })),
              dispatch: {
                invoice_no: transactionInfo.dispatch_info.invoice_no || '',
                doc_no: transactionInfo.dispatch_info.doc_no || '',
                delivery_note: transactionInfo.dispatch_info.delivery_note || '',
                destination: transactionInfo.dispatch_info.destination || '',
                carrier_name: transactionInfo.dispatch_info.carrier_name || '',
                bill_of_lading: transactionInfo.dispatch_info.bill_of_lading || '',
                dispatch_date: transactionInfo.dispatch_info.dispatch_date || '',
                motor: transactionInfo.dispatch_info.motor || '',
                e_way_bill: transactionInfo.dispatch_info.e_way_bill || '',
              },
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTransactionSales();
  }, []);

  const fetchLedger = () => {
    // setting the loader for it
    setIsLoading(true);
    try {
      const inputData = JSON.stringify({
        function_type: 'fetch_ledgers',
        company_id: companyId || company,
        search_string: '',
        limit: '20',
        page: '1',
        jwt_token: userToken,
        api_signature: API_SIGN,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_TRANSACTION_LEDGER, 'plain/text')
        .then((response) => {
          setIsLoading(false);
          console.log('LEDGER: ', response);
          if (response.data.status === 1) {
            setLedgerDetails(response.data.response.ledgers_info);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLedger();
  }, []);

  const fetchStock = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
    setIsLoading(true);
    try {
      // API request for fetch stock
      const inputData = JSON.stringify({
        function_type: 'fetch_stock',
        company_id: companyId || company,
        search_string: searchStringForce || searchString,
        limit: rowsPerPageForce || rowsPerPage,
        page: !pageForce ? page + 1 : parseInt(pageForce, 10) + 1,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_STOCK, 'plain/text')
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.data.status === 1) {
            setTotalRows(parseInt(response.data.response.data.total_rows, 10));
            setStockDetails(response.data.response.data.stock_info);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSpecificCompany = () => {
    setIsLoading(true);
    try {
      // API request for fetch SPECIFIC company
      const inputData = JSON.stringify({
        function_type: 'fetch_specific_companies',
        company_id: companyId || company,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', SPECIFIC_COMPANY, 'plain/text')
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.data.status === 1) {
            const companyData = response.data.response.data;
            setCompanyDetails(companyData);

            // Update form values with company details when fetched
            setLastFormValues((prevValues) => ({
              ...prevValues,
              my_company_details: {
                company_address: companyData.company_address || '',
                company_name: companyData.company_name || '',
                email: companyData.email || '',
                mobile: companyData.mobile || '',
                gst: companyData.gst || '',
              },
            }));
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLastCompanyData = () => {
    setIsLoading(true);
    try {
      // API request for fetch last company data
      const inputData = JSON.stringify({
        function_type: 'get_last_company',
        company_id: companyId || company,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', LAST_COMPANY_DATA, 'plain/text')
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.data.status === 1) {
            const companyData = response.data.response.data;
            setLastCompanyData(companyData);

            // Update form values with company details when fetched
            setLastFormValues((prevValues) => ({
              ...prevValues,
              my_company_details: {
                company_address: companyData?.company_address || '',
                company_name: companyData?.company_name || '',
                email: companyData?.email || '',
                mobile: companyData?.mobile || '',
                gst: companyData?.gst || '',
              },
            }));
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLastBillingShippingData = () => {
    setIsLoading(true);
    try {
      // API request for fetch last company data
      const inputData = JSON.stringify({
        function_type: 'get_billing_shipping',
        ledgername: ledgerName,
        company_id: companyId || company,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', LAST_BILLING_SHIPPING_DATA, 'plain/text')
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.data.status === 1) {
            const billingShippingDataArray = response.data.response.data;

            if (billingShippingDataArray && billingShippingDataArray.length > 0) {
              const billingData = billingShippingDataArray[0];
              const shippingData = billingShippingDataArray[1];

              setLastFormValues((prevValues) => ({
                ...prevValues,
                billing_address: {
                  company_name: shippingData?.company_name || '',
                  company_address: billingData?.company_address || '',
                  email: billingData?.email || '',
                  mobile: billingData?.mobile || '',
                  gst: billingData?.gst || '',
                },
                shipping_address: {
                  company_name: shippingData?.company_name || '',
                  company_address: shippingData?.company_address || '',
                  email: shippingData?.email || '',
                  mobile: shippingData?.mobile || '',
                  gst: shippingData?.gst || '',
                },
              }));
            } else {
              console.log('No billing or shipping data available.');
            }
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (ledgerName) {
      fetchLastBillingShippingData();
    }
  }, [ledgerName]);

  useEffect(() => {
    fetchLastCompanyData();
  }, []);

  useEffect(() => {
    fetchSpecificCompany();
  }, [companyId || company]);

  useEffect(() => {
    fetchStock();
  }, []);

  const handleAddRow = () => {
    // Add a new row to the stock_info array in formik
    formik.setFieldValue('stock_info', [
      ...formik.values.stock_info,
      {
        stock_name: '',
        hsn_code: '',
        qty: '1',
        mrp: '',
        gst: {
          cgst: { percentage_bool: '', percentage: '', amount: '' },
          sgst: { percentage_bool: '', percentage: '', amount: '' },
          igst: { percentage_bool: '', percentage: '', amount: '' },
          cess: { percentage_bool: '', percentage: '', amount: '' },
        },
        discount: { percentage_bool: '', percentage: '', amount: '' },
        other_tax: { percentage_bool: '', percentage: '', amount: '' },
        total_amount: '0.00',
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedStockInfo = formik.values.stock_info.filter((_, i) => i !== index);
    formik.setFieldValue('stock_info', updatedStockInfo);
  };

  const handleInputChange = (index, field, value) => {
    const updatedStockInfo = formik.values.stock_info.map((row, i) => (i === index ? { ...row, [field]: value } : row));
    formik.setFieldValue('stock_info', updatedStockInfo);
  };

  const resetForm = () => {
    setFormValues('');
  };

  const saveGoBack = () => {
    if (isCreate) {
      formik.resetForm();
      resetForm();
    }
    navigate(`/dashboard/transactions/billing?company_id=${companyId}`);
  };

  const saveAddAnother = () => {
    if (isCreate) {
      formik.resetForm();
      resetForm();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: formValues?.date || '',
      ledgername: formValues?.ledgername || '',
      transactiontype: formValues?.transactiontype || 'sales',
      narration: formValues?.narration || '',
      my_company_details: {
        company_name: formValues?.my_company_details?.company_name
          ? formValues?.my_company_details?.company_name
          : lastFormValues?.my_company_details?.company_name,
        company_address: formValues?.my_company_details?.company_address
          ? formValues?.my_company_details?.company_address
          : lastFormValues?.my_company_details?.company_address,
        email: formValues?.my_company_details?.email
          ? formValues?.my_company_details?.email
          : lastFormValues?.my_company_details?.email,
        mobile: formValues?.my_company_details?.mobile
          ? formValues?.my_company_details?.mobile
          : lastFormValues?.my_company_details?.mobile,
        gst: formValues?.my_company_details?.gst
          ? formValues?.my_company_details?.gst
          : lastFormValues?.my_company_details?.gst,
      },
      billing_shipping_same: formValues?.billing_shipping_same || 0,
      billing_address: {
        company_name: formValues?.billing_address?.company_name
          ? formValues?.billing_address?.company_name
          : lastFormValues?.billing_address?.company_name,
        company_address: formValues?.billing_address?.company_address
          ? formValues?.billing_address?.company_address
          : lastFormValues?.billing_address?.company_address,
        email: formValues?.billing_address?.email
          ? formValues?.billing_address?.email
          : lastFormValues?.billing_address?.email,
        mobile: formValues?.billing_address?.mobile
          ? formValues?.billing_address?.mobile
          : lastFormValues?.billing_address?.mobile,
        gst: formValues?.billing_address?.gst ? formValues?.billing_address?.gst : lastFormValues?.billing_address?.gst,
      },
      shipping_address: {
        company_name: formValues?.company_name
          ? formValues?.company_name
          : lastFormValues?.shipping_address?.company_name,
        company_address: formValues?.company_address
          ? formValues?.company_address
          : lastFormValues?.shipping_address?.company_address,
        email: formValues?.shipping_address?.email
          ? formValues?.shipping_address?.email
          : lastFormValues?.shipping_address?.email,
        mobile: formValues?.shipping_address?.mobile
          ? formValues?.shipping_address?.mobile
          : lastFormValues?.shipping_address?.mobile,
        gst: formValues?.shipping_address?.gst
          ? formValues?.shipping_address?.gst
          : lastFormValues?.shipping_address?.gst,
      },
      total_gst_discount: [
        {
          discount: {
            percentage_bool: formValues?.percentage_bool || '',
            percentage: formValues?.percentage || '',
            amount: formValues?.amount || '',
          },
          gst: {
            cgst: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
            sgst: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
            igst: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
            cess: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
          },
          other_tax: {
            percentage_bool: formValues?.percentage_bool || '',
            percentage: formValues?.percentage || '',
            amount: formValues?.amount || '',
          },
        },
      ],
      stock_info: [
        {
          stock_name: formValues?.stock_name || '',
          hsn_code: formValues?.hsn_code || '',
          qty: formValues?.qty || '1',
          discount: {
            percentage_bool: formValues?.percentage_bool || '',
            percentage: formValues?.percentage || '',
            amount: formValues?.amount || '',
          },
          mrp: formValues?.mrp || '',
          gst: {
            cgst: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
            sgst: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
            igst: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
            cess: {
              percentage_bool: formValues?.percentage_bool || '',
              percentage: formValues?.percentage || '',
              amount: formValues?.amount || '',
            },
          },
          other_tax: {
            percentage_bool: formValues?.percentage_bool || '',
            percentage: formValues?.percentage || '',
            amount: formValues?.amount || '',
          },
          total_amount: formValues?.total_amount || '0.00',
        },
      ],
      dispatch: {
        invoice_no: formValues?.invoice_no || '',
        doc_no: formValues?.doc_no || '',
        delivery_note: formValues?.delivery_note || '',
        destination: formValues?.destination || '',
        carrier_name: formValues?.carrier_name || '',
        bill_of_lading: formValues?.bill_of_lading || '',
        dispatch_date: formValues?.dispatch_date || '',
        motor: formValues?.motor || '',
        e_way_bill: formValues?.e_way_bill || '',
      },
    },
    validationSchema: Yup.object({
      dispatch: Yup.object().shape({
        invoice_no: Yup.string().required('Invoice no. is required'),
      }),
    }),
    onSubmit: async (values, helpers) => {
      setIsLoading(true);
      setErrorMessage('');
      console.log(values);
      try {
        const generateConditionalObject = (values) => {
          // console.log(values);
          const result = {};

          // Discount
          if (values.discount?.percentage_bool === 1) {
            result.discount = {
              percentage_bool: values.discount.percentage_bool,
              percentage: values.discount.percentage,
            };
          } else if (values.discount?.percentage_bool === 0) {
            result.discount = {
              percentage_bool: values.discount.percentage_bool,
              amount: values.discount.amount,
            };
          } else {
            result.discount = null;
          }

          // GST
          result.gst = {};
          if (values.gst?.cgst?.percentage_bool === 1) {
            result.gst.cgst = {
              percentage_bool: values.gst.cgst.percentage_bool,
              percentage: values.gst.cgst.percentage,
            };
          } else if (values.gst?.cgst?.percentage_bool === 0) {
            result.gst.cgst = {
              percentage_bool: values.gst.cgst.percentage_bool,
              amount: values.gst.cgst.amount,
            };
          } else {
            result.gst.cgst = null;
          }

          if (values.gst?.sgst?.percentage_bool === 1) {
            result.gst.sgst = {
              percentage_bool: values.gst.sgst.percentage_bool,
              percentage: values.gst.sgst.percentage,
            };
          } else if (values.gst?.sgst?.percentage_bool === 0) {
            result.gst.sgst = {
              percentage_bool: values.gst.sgst.percentage_bool,
              amount: values.gst.sgst.amount,
            };
          } else {
            result.gst.sgst = null;
          }

          if (values.gst?.igst?.percentage_bool === 1) {
            result.gst.igst = {
              percentage_bool: values.gst.igst.percentage_bool,
              percentage: values.gst.igst.percentage,
            };
          } else if (values.gst?.igst?.percentage_bool === 0) {
            result.gst.igst = {
              percentage_bool: values.gst.igst.percentage_bool,
              amount: values.gst.igst.amount,
            };
          } else {
            result.gst.igst = null;
          }

          if (values.gst?.cess?.percentage_bool === 1) {
            result.gst.cess = {
              percentage_bool: values.gst.cess.percentage_bool,
              percentage: values.gst.cess.percentage,
            };
          } else if (values.gst?.cess?.percentage_bool === 0) {
            result.gst.cess = {
              percentage_bool: values.gst.cess.percentage_bool,
              amount: values.gst.cess.amount,
            };
          } else {
            result.gst.cess = null;
          }

          // Remove null gst objects
          Object.keys(result.gst).forEach((key) => {
            if (result.gst[key] === null) delete result.gst[key];
          });

          if (Object.keys(result.gst).length === 0) {
            result.gst = null;
          }

          // Other Tax
          if (values.other_tax?.percentage_bool === 1) {
            result.other_tax = {
              percentage_bool: values.other_tax.percentage_bool,
              percentage: values.other_tax.percentage,
            };
          } else if (values.other_tax?.percentage_bool === 0) {
            result.other_tax = {
              percentage_bool: values.other_tax.percentage_bool,
              amount: values.other_tax.amount,
            };
          } else {
            result.other_tax = null;
          }

          return result;
        };
        // console.log("hii")
        // Prepare total_gst_discount
        // const stockDiscounts = values.stock_info.map((stock) => generateConditionalObject(stock));
        const totalGstDiscount = generateConditionalObject(values.total_gst_discount);

        const hasTotalGstDiscount = Object.keys(totalGstDiscount).some(
          (key) => totalGstDiscount[key] !== null && Object.keys(totalGstDiscount[key]).length > 0
        );

        // console.log(totalGstDiscount)

        const payload = {
          function_type: 'create_transaction_sales',
          company_id: companyId || company,
          date: values.date,
          ledgername: values.ledgername,
          transactiontype: values.transactiontype,
          narration: values.narration,
          my_company_details: {
            company_address: values.my_company_details.company_address,
            company_name: values.my_company_details.company_name,
            email: values.my_company_details.email,
            mobile: values.my_company_details.mobile,
            gst: values.my_company_details.gst,
          },
          billing_shipping_same: values.billing_shipping_same,
          billing_address: {
            company_address: values.billing_address.company_address,
            company_name: values.billing_address.company_name,
            email: values.billing_address.email,
            mobile: values.billing_address.mobile,
            gst: values.billing_address.gst,
          },
          shipping_address: {
            company_address: values.shipping_address.company_address,
            company_name: values.shipping_address.company_name,
            email: values.shipping_address.email,
            mobile: values.shipping_address.mobile,
            gst: values.shipping_address.gst,
          },
          total_gst_discount: hasTotalGstDiscount ? [totalGstDiscount] : [],
          stock_info: values.stock_info.map((stock) => ({
            ...stock,
            ...generateConditionalObject(stock),
          })),
          dispatch: {
            invoice_no: values.dispatch.invoice_no,
            doc_no: values.dispatch.doc_no,
            delivery_note: values.dispatch.delivery_note,
            destination: values.dispatch.destination,
            carrier_name: values.dispatch.carrier_name,
            bill_of_lading: values.dispatch.bill_of_lading,
            dispatch_date: values.dispatch.dispatch_date,
            motor: values.dispatch.motor,
            e_way_bill: values.dispatch.e_way_bill,
          },
          api_signature: API_SIGN,
          jwt_token: userToken,
        };

        const inputData = JSON.stringify(payload);
        console.log(inputData);
        // Make the API call
        setIsLoading(true);
        apiCall(inputData, 'POST', SALES_TRANSACTION, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              enqueueSnackbar(isCreate ? 'Sales bill added successfully. ' : 'Sales bill updated successfully.', {
                variant: 'success',
              });
              handleSuccessSubmit(saveGoBack, saveAddAnother);
            } else {
              setErrorMessage(response.data.response);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log('Error adding sales bill:', error.message);
            setErrorMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  const handleTotalAmount = (row, index) => {
    const totalAmount = row.qty * row.mrp;
    formik.setFieldValue(`stock_info[${index}].total_amount`, totalAmount);
  };

  return (
    <>
      <Helmet>
        <title>{isCreate ? 'Add' : 'Update'} Bill | Rejoice Diary</title>
      </Helmet>
      <Box>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="outlined"
                label="Date"
                name="date"
                type="date"
                error={!!(formik.touched.date && formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ marginBottom: '10px' }}
                value={formik.values.date}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete
                options={ledgerDetails}
                freeSolo
                fullWidth
                getOptionLabel={(option) => option?.ledgername || ''}
                onChange={(e, value) => {
                  const selectedLedgerName = value?.ledgername || '';
                  setLedgerName(selectedLedgerName);
                  formik.setFieldValue('ledgername', selectedLedgerName);
                }}
                // onChange={(e, value) => {
                //   formik.setFieldValue('ledgername', value ? value.ledgername : formik.values.ledgername);
                // }}
                value={ledgerDetails?.find((ledger) => ledger?.ledgername === formik.values.ledgername) || null}
                loading={isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ledger Name"
                    variant="outlined"
                    name="ledgername"
                    error={!!(formik.touched.ledgername && formik.errors.ledgername)}
                    helperText={formik.touched.ledgername && formik.errors.ledgername}
                    onBlur={formik.handleBlur}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ marginBottom: '10px' }}
                  />
                )}
              />
            </Grid>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Company Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Company Name"
                      name="my_company_details.company_name"
                      error={
                        !!(
                          formik.touched.my_company_details?.company_name &&
                          formik.errors.my_company_details?.company_name
                        )
                      }
                      helperText={
                        formik.touched.my_company_details?.company_name &&
                        formik.errors.my_company_details?.company_name
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.company_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Company Address"
                      name="my_company_details.company_address"
                      error={
                        !!(
                          formik.touched.my_company_details?.company_address &&
                          formik.errors.my_company_details?.company_address
                        )
                      }
                      helperText={
                        formik.touched.my_company_details?.company_address &&
                        formik.errors.my_company_details?.company_address
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.company_address}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Company Email"
                      name="my_company_details.email"
                      error={!!(formik.touched.my_company_details?.email && formik.errors.my_company_details?.email)}
                      helperText={formik.touched.my_company_details?.email && formik.errors.my_company_details?.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Mobile No."
                      name="my_company_details.mobile"
                      type="number"
                      error={!!(formik.touched.my_company_details?.mobile && formik.errors.my_company_details?.mobile)}
                      helperText={formik.touched.my_company_details?.mobile && formik.errors.my_company_details?.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.mobile}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="GSTIN"
                      name="my_company_details.gst"
                      error={!!(formik.touched.my_company_details?.gst && formik.errors.my_company_details?.gst)}
                      helperText={formik.touched.my_company_details?.gst && formik.errors.my_company_details?.gst}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.gst}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Billing and Shipping Address</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Billing Address Fields */}
                <Typography>Billing Address</Typography>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                      options={ledgerDetails}
                      freeSolo
                      fullWidth
                      getOptionLabel={(option) => option?.ledgername || ''}
                      onChange={(e, value) => {
                        const selectedLedgerName = value?.ledgername || '';
                        setLedgerName(selectedLedgerName); // Set the ledgerName when a value is selected
                        formik.setFieldValue('billing_address.company_name', selectedLedgerName);
                      }}
                      value={
                        ledgerDetails?.find(
                          (ledger) => ledger?.ledgername === formik.values.billing_address?.company_name
                        ) || null
                      }
                      loading={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Billing Name"
                          variant="outlined"
                          // name="billing_address.company_name"
                          // error={
                          //   !!(
                          //     formik.touched.billing_address?.company_name &&
                          //     formik.errors.billing_address?.company_name
                          //   )
                          // }
                          // helperText={
                          //   formik.touched.billing_address?.company_name && formik.errors.billing_address?.company_name
                          // }
                          // onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '10px' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Billing Address"
                      name="billing_address.company_address"
                      value={formik.values.billing_address?.company_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      error={
                        !!(
                          formik.touched.billing_address?.company_address &&
                          formik.errors.billing_address?.company_address
                        )
                      }
                      helperText={
                        formik.touched.billing_address?.company_address &&
                        formik.errors.billing_address?.company_address
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Billing Email"
                      name="billing_address.email"
                      value={formik.values.billing_address?.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      error={!!(formik.touched.billing_address?.email && formik.errors.billing_address?.email)}
                      helperText={formik.touched.billing_address?.email && formik.errors.billing_address?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Billing Mobile"
                      name="billing_address.mobile"
                      value={formik.values.billing_address?.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      error={!!(formik.touched.billing_address?.mobile && formik.errors.billing_address?.mobile)}
                      helperText={formik.touched.billing_address?.mobile && formik.errors.billing_address?.mobile}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Billing GST"
                      name="billing_address.gst"
                      value={formik.values.billing_address?.gst}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      error={!!(formik.touched.billing_address?.gst && formik.errors.billing_address?.gst)}
                      helperText={formik.touched.billing_address?.gst && formik.errors.billing_address?.gst}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="billing_shipping_same"
                          checked={formik.values.billing_shipping_same}
                          onChange={(event) => formik.setFieldValue('billing_shipping_same', event.target.checked)}
                          error={!!(formik.touched.billing_shipping_same && formik.errors.billing_shipping_same)}
                          helperText={formik.touched.billing_shipping_same && formik.errors.billing_shipping_same}
                        />
                      }
                      label="Billing & Shipping Address Same"
                    />
                  </Grid>

                  {/* Shipping Address Fields */}
                  {!formik.values.billing_shipping_same && (
                    <>
                      <Grid item xs={12} sm={12} md={12} mb={1}>
                        <Typography mt={2}>Shipping Address</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                          options={ledgerDetails}
                          freeSolo
                          fullWidth
                          getOptionLabel={(option) => option?.ledgername || ''}
                          value={
                            ledgerDetails?.find(
                              (ledger) => ledger?.ledgername === formik.values.shipping_address?.company_name
                            ) || null
                          }
                          onChange={(e, value) => {
                            formik.setFieldValue('shipping_address.company_name', value?.ledgername || '');
                          }}
                          loading={isLoading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Shipping Name"
                              variant="outlined"
                              // name="shipping_address.company_name"
                              // error={
                              //   !!(
                              //     formik.touched.shipping_address?.company_name &&
                              //     formik.errors.shipping_address?.company_name
                              //   )
                              // }
                              // helperText={
                              //   formik.touched.shipping_address?.company_name &&
                              //   formik.errors.shipping_address?.company_name
                              // }
                              // onBlur={formik.handleBlur}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ marginBottom: '10px' }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping Address"
                          name="shipping_address.company_address"
                          value={formik.values.shipping_address?.company_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={
                            !!(
                              formik.touched.shipping_address?.company_address &&
                              formik.errors.shipping_address?.company_address
                            )
                          }
                          helperText={
                            formik.touched.shipping_address?.company_address &&
                            formik.errors.shipping_address?.company_address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping Email"
                          name="shipping_address.email"
                          value={formik.values.shipping_address?.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={!!(formik.touched.shipping_address?.email && formik.errors.shipping_address?.email)}
                          helperText={formik.touched.shipping_address?.email && formik.errors.shipping_address?.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping Mobile"
                          name="shipping_address.mobile"
                          value={formik.values.shipping_address?.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={!!(formik.touched.shipping_address?.mobile && formik.errors.shipping_address?.mobile)}
                          helperText={formik.touched.shipping_address?.mobile && formik.errors.shipping_address?.mobile}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping GST"
                          name="shipping_address.gst"
                          value={formik.values.shipping_address?.gst}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={!!(formik.touched.shipping_address?.gst && formik.errors.shipping_address?.gst)}
                          helperText={formik.touched.shipping_address?.gst && formik.errors.shipping_address?.gst}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Dispatch Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Invoice No."
                      name="dispatch.invoice_no"
                      error={!!(formik.touched.dispatch?.invoice_no && formik.errors.dispatch?.invoice_no)}
                      helperText={formik.touched.dispatch?.invoice_no && formik.errors.dispatch?.invoice_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.invoice_no}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Delivery Note"
                      name="dispatch.delivery_note"
                      error={!!(formik.touched.dispatch?.delivery_note && formik.errors.dispatch?.delivery_note)}
                      helperText={formik.touched.dispatch?.delivery_note && formik.errors.dispatch?.delivery_note}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.delivery_note}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Dispatch Doc No."
                      name="dispatch.doc_no"
                      error={!!(formik.touched.dispatch?.doc_no && formik.errors.dispatch?.doc_no)}
                      helperText={formik.touched.dispatch?.doc_no && formik.errors.dispatch?.doc_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.doc_no}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Destination"
                      name="dispatch.destination"
                      error={!!(formik.touched.dispatch?.destination && formik.errors.dispatch?.destination)}
                      helperText={formik.touched.dispatch?.destination && formik.errors.dispatch?.destination}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.destination}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Carrier name/Agent"
                      name="dispatch.carrier_name"
                      error={!!(formik.touched.dispatch?.carrier_name && formik.errors.dispatch?.carrier_name)}
                      helperText={formik.touched.dispatch?.carrier_name && formik.errors.dispatch?.carrier_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.carrier_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Bill of Lading/LR-RR No."
                      name="dispatch.bill_of_lading"
                      error={!!(formik.touched.dispatch?.bill_of_lading && formik.errors.dispatch?.bill_of_lading)}
                      helperText={formik.touched.dispatch?.bill_of_lading && formik.errors.dispatch?.bill_of_lading}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.bill_of_lading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Dispatch Date"
                      name="dispatch.dispatch_date"
                      type="date"
                      error={!!(formik.touched.dispatch?.dispatch_date && formik.errors.dispatch?.dispatch_date)}
                      helperText={formik.touched.dispatch?.dispatch_date && formik.errors.dispatch?.dispatch_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '10px' }}
                      value={formik.values.dispatch?.dispatch_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Motor Vehicle No."
                      name="dispatch.motor"
                      error={!!(formik.touched.dispatch?.motor && formik.errors.dispatch?.motor)}
                      helperText={formik.touched.dispatch?.motor && formik.errors.dispatch?.motor}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.motor}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography mb={2}>GST Details</Typography>
                    <TextField
                      variant="outlined"
                      label="Eway Number"
                      name="dispatch.e_way_bill"
                      error={!!(formik.touched.dispatch?.e_way_bill && formik.errors.dispatch?.e_way_bill)}
                      helperText={formik.touched.dispatch?.e_way_bill && formik.errors.dispatch?.e_way_bill}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formik.values.dispatch?.e_way_bill}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Item Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '50%' }}>Name of Item</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>HSN Code</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>Qty</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>MRP</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>Amount</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>GST & Discount</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values?.stock_info?.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell size="small" style={{ ...cellStyle, textAlign: 'right' }}>
                              <Autocomplete
                                options={stockDetails}
                                fullWidth
                                freeSolo
                                size="small"
                                loading={isLoading}
                                getOptionLabel={(option) => option?.item_name || ''}
                                value={stockDetails?.find((item) => item.item_name === row.stock_name) || null}
                                onInputChange={(e, newInputValue) => {
                                  setSearchString(newInputValue);
                                  fetchStock(newInputValue);
                                }}
                                onChange={(e, value) => {
                                  formik.setFieldValue(
                                    `stock_info[${index}].stock_name`,
                                    value ? value.item_name : row.stock_name
                                  );
                                  // formik.setFieldValue(`stock_info[${index}].mrp`, value ? value.mrp : '');
                                  // formik.setFieldValue(`stock_info[${index}].qty`, value ? value.qty : '');
                                  // formik.setFieldValue(`stock_info[${index}].hsn_code`, value ? value.hsn_code : '');
                                  // formik.setFieldValue(
                                  //   `stock_info[${index}].total_amount`,
                                  //   value ? value.total_amount : ''
                                  // );
                                  // formik.setFieldValue(`stock_info[${index}].gst`, value ? value.gst : '');
                                  // formik.setFieldValue(`stock_info[${index}].discount`, value ? value.discount : '');
                                  // formik.setFieldValue(`stock_info[${index}].other_tax`, value ? value.other_tax : '');
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Name of Item" variant="outlined" fullWidth />
                                )}
                              />
                            </TableCell>
                            <TableCell size="small" style={cellStyle}>
                              <TextField
                                variant="outlined"
                                size="small"
                                label="Hsn Code"
                                value={row.hsn_code || ''}
                                onChange={(e) => formik.setFieldValue(`stock_info[${index}].hsn_code`, e.target.value)}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell size="small" style={cellStyle}>
                              <TextField
                                variant="outlined"
                                size="small"
                                label="Qty"
                                value={row.qty || '1'}
                                onChange={(e) => formik.setFieldValue(`stock_info[${index}].qty`, e.target.value)}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell size="small" style={cellStyle}>
                              <TextField
                                variant="outlined"
                                size="small"
                                label="MRP"
                                value={row.mrp || ''}
                                onChange={(e) => formik.setFieldValue(`stock_info[${index}].mrp`, e.target.value)}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell size="small" style={cellStyle}>
                              <TextField
                                variant="outlined"
                                size="small"
                                label="Amount"
                                value={parseFloat(row.total_amount).toFixed(2) || ''}
                                onChange={(e) =>
                                  formik.setFieldValue(`stock_info[${index}].total_amount`, e.target.value)
                                }
                                fullWidth
                                disabled
                              />
                            </TableCell>
                            <TableCell size="small" style={cellStyle}>
                              <Button
                                onClick={() => {
                                  setGstDiscountDialog(index);
                                }}
                                size={'small'}
                              >
                                Manage
                              </Button>

                              {/* // per item gst dialog */}
                              <DialogGSTDiscount
                                open={gstDiscountDialog !== false}
                                index={gstDiscountDialog}
                                rowValues={
                                  gstDiscountDialog !== false ? formik.values.stock_info[gstDiscountDialog] : {}
                                }
                                setFieldValue={formik.setFieldValue}
                                companyId={companyId || company}
                                userToken={userToken}
                                closeDialog={setGstDiscountDialog}
                                handleInputChange={handleInputChange}
                              />
                            </TableCell>
                            <TableCell size="small" cellStyle>
                              {index !== 0 && (
                                <IconButton size="small" onClick={() => handleRemoveRow(index)}>
                                  <Delete fontSize="15px" />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <Grid container justifyContent="flex-start" padding={2}>
                      <Button size="small" variant="contained" color="secondary" onClick={handleAddRow}>
                        {/* <IconButton>
                          <Add  sx={{ color: 'white' }} />
                        </IconButton> */}
                        Add Row
                      </Button>
                    </Grid>
                    <Grid container justifyContent="flex-start">
                      <Button size="small" variant="text" onClick={() => setGstDiscountDialogAllOpen(true)}>
                        Apply GST & Discount to All
                      </Button>

                      {/* Dialog for applying GST, Discount to all items */}
                      <DialogGSTDiscountAll
                        open={gstDiscountDialogAllOpen}
                        stockInfo={formik.values.stock_info}
                        totalGSTdiscount={formik.values.total_gst_discount}
                        setFieldValue={formik.setFieldValue}
                        closeDialog={setGstDiscountDialogAllOpen}
                      />
                    </Grid>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Grid item xs={12} sm={12} md={12} mt={2}>
              <TextField
                variant="outlined"
                label="Narration"
                name="narration"
                multiline
                rows={3}
                error={!!(formik.touched.narration && formik.errors.narration)}
                helperText={formik.touched.narration && formik.errors.narration}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ marginBottom: '10px' }}
                value={formik.values.narration}
              />
            </Grid>

            <CustomAddButton
              btnType={'submit'}
              buttonTitle={isCreate ? 'Add' : 'Update'}
              variant="contained"
              disabled={false}
              styleProps={{ margin: '20px 0px' }}
              onClick={null}
              isLoading={isLoading}
            />
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default AddBill;
