import { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import BreadCrumbs from '../../components/breadCrumb/BreadCrumbs';
import { decryptToken } from '../../components/security/securityFunctions';
import { apiCall } from '../../components/functions/apiCall';
import { API_SIGN, DELETE_PASSWORD_CONFIRM } from '../../variables/api-variables';

const ManageSettings = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const [currentTab, setCurrentTab] = useState('delete');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const handleProceedClick = () => {
    setOpenDialog(true); // Open dialog
  };

  const handleCloseDialog = () => {
    formik.resetForm(); // Reset form on dialog close
    setOpenDialog(false); // Close dialog without deleting
  };

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        const input_data = JSON.stringify({
          function_type: 'settings',
          password: values.password,
          api_signature: API_SIGN,
          jwt_token: userToken,
        });
        console.log(input_data);
        apiCall(input_data, 'POST', DELETE_PASSWORD_CONFIRM, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              enqueueSnackbar('Your Account deleted successfully.', {
                variant: 'success',
                autoHideDuration: 2000,
              });
              setOpenDialog(false); // Close dialog on successful delete
              formik.resetForm(); // Clear password field on successful delete
              Cookies.remove('user_data_rejoice_daybook');
              navigate('/login', { replace: true });
              window.location.reload();
            } else {
              enqueueSnackbar(response.data.response, {
                variant: 'error',
                autoHideDuration: 2000,
              });
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar('Error occurred while deleting.', { variant: 'error', autoHideDuration: 2000 });
          });
      } catch (err) {
        console.log(err);
        enqueueSnackbar('Error occurred.', { variant: 'error', autoHideDuration: 2000 });
      }
    },
  });

  return (
    <>
      <Helmet>
        {' '}
        <title>Settings | Rejoice Diary</title>
      </Helmet>

      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path="companies" />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title="Settings" />
              </Stack>
            </Box>
            <Box sx={{ margin: '20px 0px' }}>
              <Tabs value={currentTab} onChange={handleTabChange}>
                {currentTab === 'delete' && <Tab label="Delete Account Settings" value="delete" />}
              </Tabs>

              {currentTab === 'delete' && (
                <Box>
                  <h4>To delete your account please click on proceed</h4>
                  <Button variant="contained" onClick={handleProceedClick}>
                    Proceed
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Account Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter your password to confirm account deletion. This action cannot be undone.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              // onCopy={(e) => e.preventDefault()} // Prevent copying
              // onPaste={(e) => e.preventDefault()} // Prevent pasting
              // onCut={(e) => e.preventDefault()} // Prevent cutting
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="error" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ManageSettings;