import Cookies from "js-cookie";
import { Box, Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { decryptToken } from "../security/securityFunctions";

const BreadCrumbs = ({ path }) => {
    // we will explode the path and make an array of it
    // the element for array exists while display
    const workflow_type = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}')?.workflow_type;
    console.log(workflow_type)


    const pathList = path.split("/");


    return (
        <Box m={2}>
            {workflow_type === "enterprise" ? (<Breadcrumbs aria-label="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                <Link href="/dashboard/home" color={"inherit"} sx={{ textDecoration: 'none' }}>Home</Link>
                {pathList.includes("users") ? (<Link href="/dashboard/users" color={"inherit"} sx={{ textDecoration: 'none' }}>Users</Link>) : null}
                {pathList.includes("companies") ? (<Link href="/dashboard/companies" color={"inherit"} sx={{ textDecoration: 'none' }}>Companies</Link>) : null}
                {pathList.includes("transactions") ? (<Link href="/dashboard/transactions" color={"inherit"} sx={{ textDecoration: 'none' }}>Transactions</Link>) : null}
                {pathList.includes("addTransaction") ? (<Link href="/dashboard/transactions/addTransaction" color={"inherit"} sx={{ textDecoration: 'none' }}>Transactions</Link>) : null}
                {(pathList.includes("categories")) ? (<Link href="/dashboard/transactions/categories" color={"inherit"} sx={{ textDecoration: 'none' }}>Categories</Link>) : null}
                {pathList.includes("addCategory") ? (<Link href="/dashboard/transactions/addCategory" color={"inherit"} sx={{ textDecoration: 'none' }}>Categories</Link>) : null}
                {(pathList.includes("wallet")) ? (<Link href="/dashboard/transactions/wallet" color={"inherit"} sx={{ textDecoration: 'none' }}>Wallet</Link>) : ("")}
                {pathList.includes("reminders") ? (<Link href="/dashboard/reminders" color={"inherit"} sx={{ textDecoration: 'none' }}>Reminders</Link>) : null}
                {pathList.includes("displayfiles") ? (<Link href="/dashboard/displayfiles" color={"inherit"} sx={{ textDecoration: 'none' }}>My Files</Link>) : null}
                {pathList.includes("fileCategory") ? (<Link href="/dashboard/displayfiles/fileCategories" color={"inherit"} sx={{ textDecoration: 'none' }}>File Category</Link>) : null}
                {(pathList.includes("ledgers")) ? (<Link href="/dashboard/transactions/ledgers" color={"inherit"} sx={{ textDecoration: 'none' }}>Ledgers</Link>) : ("")}
                {(pathList.includes("addLedger")) ? (<Link href="/dashboard/transactions/addLedger" color={"inherit"} sx={{ textDecoration: 'none' }}>Ledgers</Link>) : ("")}
                {(pathList.includes("billing")) ? (<Link href="/dashboard/transactions/billing" color={"inherit"} sx={{ textDecoration: 'none' }}>Billing</Link>) : ("")}
                {(pathList.includes("addBill")) ? (<Link href="/dashboard/transactions/addBill" color={"inherit"} sx={{ textDecoration: 'none' }}>Billing</Link>) : ("")}


                {/* <Link to="/dashboard/users">Users</Link> */}
            </Breadcrumbs>) : ("")}

        </Box>
    )
}

export default BreadCrumbs;