import * as React from 'react';
import { useEffect, useState } from "react";
import { Container, Button, CircularProgress, Menu, MenuList, MenuItem, Divider } from "@mui/material";
import { styled, alpha } from '@mui/material/styles';
import { Add, ArrowBackIos, KeyboardArrowDown } from "@mui/icons-material";
import Cookies from 'js-cookie';
import palette from '../../theme/palette';

const CustomAddButton = ({ btnType, buttonTitle, variant, onClick, disabled = false, isLoading, styleProps }) => {
    // console.log(btnType, buttonTitle, variant, onClick, disabled = false, isLoading, styleProps)

    // try fetching a cookie named user_components_rejoice_daybook (this has to be an object)
    // if it exists , then look for a key named save_btn_preference and is not empty
    // two values : save_go_back / save_add_another
    // if save_add_another append to title : btnTitle+'And Add Another'
    // if save_go_back append to title : btnTitle
    // jis pr bhi click vo value cookie mei set krdo
    // if it key does not exist but user_components_rejoice_daybook exists : save_btn_preference : save_go_back
    // if it user_components_rejoice_daybook not exists :{ save_btn_preference : save_go_back }
    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
            style={{ fontSize: '10pt' }}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            fontSize: '10px',
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                fontSize: '10pt', // Adjust the font size here
                '& .MuiSvgIcon-root': {
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickMenuList = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [updatedTitle, setUpdatedTitle] = useState(buttonTitle);

    const updateSavedPreference = (value) => {
        let cookie = Cookies.get('user_components_rejoice_daybook');
        if (cookie) {
            cookie = JSON.parse(cookie);
            console.log({ ...cookie, save_btn_preference: value });
            Cookies.set('user_components_rejoice_daybook', JSON.stringify({ ...cookie, save_btn_preference: value }));
        } else {
            Cookies.set('user_components_rejoice_daybook', JSON.stringify({ save_btn_preference: value }));
        }
        setUpdatedTitle(value)
    }

    useEffect(() => {
        let cookie = Cookies.get('user_components_rejoice_daybook');
        // console.log(cookie);
        if (cookie) {
            const savedPreference = JSON.parse(cookie)?.save_btn_preference;
            if (savedPreference) {
                setUpdatedTitle(savedPreference);
            } else {
                cookie = JSON.parse(cookie);
                Cookies.set('user_components_rejoice_daybook', JSON.stringify({ ...cookie, save_btn_preference: 'save_go_back' }));
                setUpdatedTitle('save_go_back')
            }
        }
    }, []);

    return (

        // onClick === null && btnType === 'submit' ? (
        <Container style={styleProps}>
            <Button variant={variant} type={btnType} sx={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', paddingRight: '8px', paddingLeft: '10px' }}>{
                !isLoading ? <>
                    {updatedTitle === 'save_go_back' ? <><ArrowBackIos fontSize='12px' sx={{ fontWeight: '600px' }} />{buttonTitle} </> : <>{buttonTitle} <Add /></>}
                </> : <CircularProgress color={'info'} size={20} />

            }</Button>

            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClickMenuList}
                sx={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '0px', background: '#dedede', color: palette.grey[600] }}

            >
                <KeyboardArrowDown />
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    updateSavedPreference('save_go_back');
                    handleClose();
                }} >

                    Save and Go Back
                </MenuItem>
                <MenuItem onClick={() => {
                    updateSavedPreference('save_add_another');
                    handleClose();
                }} >

                    Save And Add another
                </MenuItem>

            </StyledMenu>
            {/* <Button variant={variant} sx={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '0px' }}>
                //     {
                //     !isLoading ? <KeyboardArrowDown /> : <CircularProgress color={'info'} size={20} />
                //     }
            // </Button> */}

        </Container>
        //     ) :
        //         (<Button variant={variant} onClick={onClick} type={btnType}>{
        //             !isLoading ? buttonTitle : <CircularProgress color={'info'} size={20} />
        //         }</Button>)

    );
}

export default CustomAddButton;