import { Close, Delete, Edit } from '@mui/icons-material';
import { Box, Card, Button, Dialog, DialogContent, Divider, Grid, List, ListItem, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useState } from 'react'
import DeleteMyFiles from '../../../components/my_files/delete_files/DeleteMyFiles';

const SpecificFile = ({
  imageInfo,
  setShowSpecificImage,
  setOpenAddFolderOrFile,
  openAddFolderOrFile,
  setOpenEditFile,
  viewDriveItems,
}) => {
  const [value, setValue] = useState("one");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(imageInfo)
  const handleDownLoadDoc = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = imageInfo.key;
    downloadLink.download = imageInfo.filename;
    // Append the link to the document and trigger a click event
    document.body.appendChild(downloadLink);
    downloadLink.click();
    // Remove the link from the document
    document.body.removeChild(downloadLink);
  };
  // console.log(imageInfo)

  const handleFileEdit = () => {
    setOpenAddFolderOrFile(!openAddFolderOrFile);
    setOpenEditFile(imageInfo);
  };

  const handleFileDelete = () => {
    setOpenDeleteDialog(true);
  };
  return (
    <>
      <Dialog fullWidth open onClose={() => setShowSpecificImage()}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={10} xl={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "5px",
                  justifyContent: "space-between",
                }}
              >
                <Typography margin={1} color="primary" variant="h6">
                  {imageInfo.filename}
                </Typography>
                <Button
                  margin={1}
                  sx={{ marginLeft: "10px" }}
                  padding={1}
                  onClick={() => setShowSpecificImage()}
                  color="primary"
                >
                  <Close fontSize="15" />
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={10} xl={12}>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="one" label="Preview" />
                  <Tab value="two" label="File Info" />
                </Tabs>
              </Box>
              {value === "one" && (
                <Grid
                  sx={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <Card
                    sx={{
                      margin: "10px",
                      padding: "15px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {["jpg", "png", "jpeg", "webp"].includes(imageInfo.file_extension) ? (
                      <img
                        src={imageInfo.key}
                        alt={imageInfo.filename}
                        height="100px"
                        width="100px"
                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                      />
                    ) : null}
                    {["pdf"].includes(imageInfo.file_extension) ? (
                      <img
                        src="/assets/logos/LogoPdf.png"
                        alt={imageInfo.filename}
                        height="100px"
                        width="100px"
                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                      />
                    ) : null}
                    {["doc", "docx"].includes(imageInfo.file_extension) ? (
                      <img
                        src="/assets/logos/docxlogo.png"
                        alt={imageInfo.filename}
                        height="100px"
                        width="100px"
                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                      />
                    ) : null}
                    {["xls", "xlsx"].includes(imageInfo.file_extension) ? (
                      <img
                        src="/assets/logos/excelLogo.png"
                        alt={imageInfo.filename}
                        height="100px"
                        width="100px"
                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                      />
                    ) : null}
                  </Card>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      onClick={handleDownLoadDoc}
                      sx={{ margin: "5px", padding: 1, marginTop: "10px", height: "40px" }}
                    >
                      <DownloadIcon color="primary" />
                    </Button>
                    <Button
                      onClick={handleFileEdit}
                      sx={{ margin: "5px", padding: 1, height: "40px" }}
                    >
                      <Edit />
                    </Button>
                    <Button
                      onClick={handleFileDelete}
                      sx={{ margin: "5px", padding: 1, height: "40px" }}
                    >
                      <Delete />
                    </Button>
                  </div>
                </Grid>
              )}
              {value === "two" && (
                <Grid>
                  <List>

                    <ListItem>
                      <ListItemText
                        primary="Added By"
                        primaryTypographyProps={{
                          variant: "caption",
                          fontSize: 12,
                          fontWeight: 400,
                        }}
                        secondary={imageInfo.added_by}
                        secondaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                      />
                    </ListItem>


                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary="Share Mode"
                        primaryTypographyProps={{
                          variant: "caption",
                          fontSize: 12,
                          fontWeight: 400,
                        }}
                        secondary={imageInfo.share_mode}
                        secondaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                      />
                    </ListItem>
                    <Divider />
                  </List>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {openDeleteDialog && (
        <DeleteMyFiles
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          fileToDelete={imageInfo} // Pass the file info or ID to the DeleteMyFiles
          viewDriveItems={viewDriveItems}
        />
      )}
    </>
  );
}

export default SpecificFile