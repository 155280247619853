import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PercentageSwitch } from '../../../../components/switch/PercentageSwitch';
import { indianFormatNumber } from '../../../../components/functions/data_functions';

const DialogGSTDiscount = ({
  open,
  index,
  rowValues,
  setFieldValue,
  closeDialog,
}) => {
  const [values, setValues] = useState(rowValues);

  useEffect(() => {
    setValues(rowValues);
  }, [rowValues]);

  const handleClose = () => {
    closeDialog(false);
  };

  const handleSave = () => {
    setFieldValue(`stock_info[${index}].gst`, values.gst);
    setFieldValue(`stock_info[${index}].discount`, values.discount);
    setFieldValue(`stock_info[${index}].other_tax`, values.other_tax);
    setFieldValue(`stock_info[${index}].total_amount`, calculateTotal());

    closeDialog(false);
  };

  const handleInputChange = (e, field, isGst = false) => {
    const { name, value } = e.target;
    if (isGst) {
      const updatedGst = {
        ...values.gst,
        [field]: {
          ...values.gst[field],
          [name]: value,
        },
      };
      setValues({ ...values, gst: updatedGst });
    } else {
      setValues({ ...values, [field]: { ...values[field], [name]: value } });
    }
  };

  const handleSwitchChange = (checked, field, isGst = false) => {
    if (isGst) {
      const updatedGst = {
        ...values.gst,
        [field]: {
          ...values.gst[field],
          percentage_bool: checked ? 1 : 0,
          percentage: checked ? values.gst[field].percentage || '' : '',
          amount: !checked ? values.gst[field].amount || '' : '',
        },
      };
      setValues({ ...values, gst: updatedGst });
    } else {
      setValues({
        ...values,
        [field]: {
          ...values[field],
          percentage_bool: checked ? 1 : 0,
          percentage: checked ? values[field].percentage || '' : '',
          amount: !checked ? values[field].amount || '' : '',
        },
      });
    }
  };

  const gstFields = ['cgst', 'sgst', 'igst', 'cess'];

  const calculateTotal = () => {
    const baseAmount = (values.mrp || 0) * (values.qty || 0);

    const discountAmount = values.discount?.percentage_bool
      ? (baseAmount * values.discount.percentage) / 100
      : values.discount?.amount || 0;

    let totalGst = 0;
    gstFields.forEach((field) => {
      const gstValue = values.gst?.[field] || {};
      const gstAmount = gstValue.percentage_bool
        ? (baseAmount * gstValue.percentage) / 100
        : gstValue.amount || 0;
      totalGst += gstAmount;
    });

    const otherTaxAmount = values.other_tax?.percentage_bool
      ? (baseAmount * values.other_tax.percentage) / 100
      : values.other_tax?.amount || 0;

    return baseAmount - discountAmount + totalGst + otherTaxAmount;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Item GST & Discounts</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Percentage/Amount</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Discount</TableCell>
                <TableCell>
                  <PercentageSwitch
                    checked={!!values.discount?.percentage_bool}
                    onChange={(checked) => handleSwitchChange(checked, 'discount')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={values.discount?.percentage_bool ? 'percentage' : 'amount'}
                    value={
                      values.discount?.percentage_bool
                        ? values.discount?.percentage || ''
                        : values.discount?.amount || ''
                    }
                    onChange={(e) => handleInputChange(e, 'discount')}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: values.discount?.percentage_bool && (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: { style: { textAlign: 'right' } },
                    }}
                  />
                </TableCell>
              </TableRow>

              {gstFields?.map((field, idx) => {
                const gstValue = values.gst?.[field] || {};
                return (
                  <TableRow key={idx}>
                    <TableCell>{field.toUpperCase()}</TableCell>
                    <TableCell>
                      <PercentageSwitch
                        checked={!!gstValue.percentage_bool}
                        onChange={(checked) => handleSwitchChange(checked, field, true)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={gstValue.percentage_bool ? 'percentage' : 'amount'}
                        value={
                          gstValue.percentage_bool
                            ? gstValue.percentage || ''
                            : gstValue.amount || ''
                        }
                        onChange={(e) => handleInputChange(e, field, true)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: gstValue.percentage_bool && (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell>Other Tax</TableCell>
                <TableCell>
                  <PercentageSwitch
                    checked={!!values.other_tax?.percentage_bool}
                    onChange={(checked) => handleSwitchChange(checked, 'other_tax')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={values.other_tax?.percentage_bool ? 'percentage' : 'amount'}
                    value={
                      values.other_tax?.percentage_bool
                        ? values.other_tax?.percentage || ''
                        : values.other_tax?.amount || ''
                    }
                    onChange={(e) => handleInputChange(e, 'other_tax')}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment:
                        values.other_tax?.percentage_bool === 1 ? (
                          <InputAdornment position="end">%</InputAdornment>
                        ) : (
                          ''
                        ),
                      inputProps: { style: { textAlign: 'right' } },
                    }}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell>{indianFormatNumber(calculateTotal())}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGSTDiscount;