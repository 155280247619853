//
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PercentageSwitch } from '../../../../components/switch/PercentageSwitch';
import { indianFormatNumber } from '../../../../components/functions/data_functions';

const DialogGSTDiscountAll = ({ open, stockInfo, setFieldValue, closeDialog }) => {
  const [values, setValues] = useState({
    gst: {
      cgst: { percentage_bool: '', percentage: '', amount: '' },
      sgst: { percentage_bool: '', percentage: '', amount: '' },
      igst: { percentage_bool: '', percentage: '', amount: '' },
      cess: { percentage_bool: '', percentage: '', amount: '' },
    },
    discount: { percentage_bool: '', percentage: '', amount: '' },
    other_tax: { percentage_bool: '', percentage: '', amount: '' },
  });

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (open) calculateTotal();
  }, [open, stockInfo, values]);

  const calculateTotal = () => {
    let totalAmount = 0;

    stockInfo.forEach((item) => {
      const itemTotal = item.mrp * item.qty; // Total value of the item before any discount/tax

      const discountAmount =
        values.discount.percentage_bool === 1
          ? (itemTotal * values.discount.percentage) / 100
          : parseFloat(values.discount.amount || 0);

      const discountedTotal = itemTotal - discountAmount;

      const gstAmount =
        (values.gst.cgst.percentage_bool === 1
          ? (discountedTotal * values.gst.cgst.percentage) / 100
          : parseFloat(values.gst.cgst.amount || 0)) +
        (values.gst.sgst.percentage_bool === 1
          ? (discountedTotal * values.gst.sgst.percentage) / 100
          : parseFloat(values.gst.sgst.amount || 0)) +
        (values.gst.igst.percentage_bool === 1
          ? (discountedTotal * values.gst.igst.percentage) / 100
          : parseFloat(values.gst.igst.amount || 0)) +
        (values.gst.cess.percentage_bool === 1
          ? (discountedTotal * values.gst.cess.percentage) / 100
          : parseFloat(values.gst.cess.amount || 0));

      const otherTaxAmount =
        values.other_tax.percentage_bool === 1
          ? (discountedTotal * values.other_tax.percentage) / 100
          : parseFloat(values.other_tax.amount || 0);

      totalAmount += discountedTotal + gstAmount + otherTaxAmount;
    });

    console.log(totalAmount);
    setTotal(totalAmount);
  };

  const handleApplyToAll = () => {
    const { gst, discount, other_tax } = values;

    stockInfo.forEach((item, index) => {
      const itemTotal = item.mrp * item.qty;

      const discountAmount =
        discount.percentage_bool === 1 ? (itemTotal * discount.percentage) / 100 : parseFloat(discount.amount || 0);

      const discountedTotal = itemTotal - discountAmount;

      const gstAmount =
        (gst.cgst.percentage_bool === 1
          ? (discountedTotal * gst.cgst.percentage) / 100
          : parseFloat(gst.cgst.amount || 0)) +
        (gst.sgst.percentage_bool === 1
          ? (discountedTotal * gst.sgst.percentage) / 100
          : parseFloat(gst.sgst.amount || 0)) +
        (gst.igst.percentage_bool === 1
          ? (discountedTotal * gst.igst.percentage) / 100
          : parseFloat(gst.igst.amount || 0)) +
        (gst.cess.percentage_bool === 1
          ? (discountedTotal * gst.cess.percentage) / 100
          : parseFloat(gst.cess.amount || 0));

      const otherTaxAmount =
        other_tax.percentage_bool === 1
          ? (discountedTotal * other_tax.percentage) / 100
          : parseFloat(other_tax.amount || 0);

      const totalAmount = discountedTotal + gstAmount + otherTaxAmount;

      setFieldValue(`stock_info[${index}].total_amount`, totalAmount);

      // Store null values for gst, discount, and other_tax for each item, as they're applied universally
      setFieldValue(`stock_info[${index}].gst`, null);
      setFieldValue(`stock_info[${index}].discount`, null);
      setFieldValue(`stock_info[${index}].other_tax`, null);
    });

    // Store the universal gst, discount, and other_tax in total_gst_discount for reference
    setFieldValue('total_gst_discount', { gst, discount, other_tax });

    closeDialog(false);
  };

  // const calculateTotal = () => {
  //   let totalAmount = 0;

  //   stockInfo.forEach((item) => {
  //     const itemTotal = item.mrp * item.qty; // Total value of the item before any discount/tax

  //     const discountAmount = values.discount.percentage_bool
  //       ? (itemTotal * values.discount.percentage) / 100
  //       : parseFloat(values.discount.amount || 0);

  //     const discountedTotal = itemTotal - discountAmount;

  //     const gstAmount =
  //       (values.gst.cgst.percentage_bool
  //         ? (discountedTotal * values.gst.cgst.percentage) / 100
  //         : parseFloat(values.gst.cgst.amount || 0)) +
  //       (values.gst.sgst.percentage_bool
  //         ? (discountedTotal * values.gst.sgst.percentage) / 100
  //         : parseFloat(values.gst.sgst.amount || 0)) +
  //       (values.gst.igst.percentage_bool
  //         ? (discountedTotal * values.gst.igst.percentage) / 100
  //         : parseFloat(values.gst.igst.amount || 0)) +
  //       (values.gst.cess.percentage_bool
  //         ? (discountedTotal * values.gst.cess.percentage) / 100
  //         : parseFloat(values.gst.cess.amount || 0));

  //     const otherTaxAmount = values.other_tax.percentage_bool
  //       ? (discountedTotal * values.other_tax.percentage) / 100
  //       : parseFloat(values.other_tax.amount || 0);

  //     totalAmount += discountedTotal + gstAmount + otherTaxAmount;
  //   });

  //   console.log(totalAmount);
  //   setTotal(totalAmount);
  // };

  // const handleApplyToAll = () => {
  //   const { gst, discount, other_tax } = values;

  //   stockInfo.forEach((item, index) => {
  //     const itemTotal = item.mrp * item.qty;

  //     const discountAmount = discount.percentage_bool
  //       ? (itemTotal * discount.percentage) / 100
  //       : parseFloat(discount.amount || 0);

  //     const discountedTotal = itemTotal - discountAmount;

  //     const gstAmount =
  //       (gst.cgst.percentage_bool ? (discountedTotal * gst.cgst.percentage) / 100 : parseFloat(gst.cgst.amount || 0)) +
  //       (gst.sgst.percentage_bool ? (discountedTotal * gst.sgst.percentage) / 100 : parseFloat(gst.sgst.amount || 0)) +
  //       (gst.igst.percentage_bool ? (discountedTotal * gst.igst.percentage) / 100 : parseFloat(gst.igst.amount || 0)) +
  //       (gst.cess.percentage_bool ? (discountedTotal * gst.cess.percentage) / 100 : parseFloat(gst.cess.amount || 0));

  //     const otherTaxAmount = other_tax.percentage_bool
  //       ? (discountedTotal * other_tax.percentage) / 100
  //       : parseFloat(other_tax.amount || 0);

  //     const totalAmount = discountedTotal + gstAmount + otherTaxAmount;

  //     setFieldValue(`stock_info[${index}].total_amount`, totalAmount);

  //     // Store null values for gst, discount, and other_tax for each item, as they're applied universally
  //     setFieldValue(`stock_info[${index}].gst`, null);
  //     setFieldValue(`stock_info[${index}].discount`, null);
  //     setFieldValue(`stock_info[${index}].other_tax`, null);
  //   });

  //   // Store the universal gst, discount, and other_tax in total_gst_discount for reference
  //   setFieldValue('total_gst_discount', { gst, discount, other_tax });

  //   closeDialog(false);
  // };

  return (
    <Dialog open={open} onClose={() => closeDialog(false)}>
      <DialogTitle>Apply GST & Discount to All Items</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              {/* Discount Section */}
              <TableRow>
                <TableCell>Discount</TableCell>
                <TableCell>
                  <PercentageSwitch
                    checked={values.discount.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = { ...prev, discount: { ...prev.discount, percentage_bool: e.target.checked ? 1 : 0} };
                        calculateTotal();
                        return newValue;
                      })
                    }
                  />
                  <TextField
                    label={values.discount.percentage_bool === 1 ? 'Percentage (%)' : 'Amount'}
                    value={values.discount.percentage_bool === 1 ? values.discount.percentage : values.discount.amount}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          discount: {
                            ...prev.discount,
                            [values.discount.percentage_bool === 1 ? 'percentage' : 'amount']: e.target.value,
                          },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{values.discount.percentage_bool === 1 ? '%' : '₹'}</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>

              {/* GST Section */}
              <TableRow>
                <TableCell>GST</TableCell>
                <TableCell>
                  <Typography variant="subtitle2">CGST</Typography>
                  <PercentageSwitch
                    checked={values.gst.cgst.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: { ...prev.gst, cgst: { ...prev.gst.cgst, percentage_bool: e.target.checked ? 1 : 0 } },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                  />
                  <TextField
                    label={values.gst.cgst.percentage_bool === 1 ? 'Percentage (%)' : 'Amount'}
                    value={values.gst.cgst.percentage_bool === 1 ? values.gst.cgst.percentage : values.gst.cgst.amount}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: {
                            ...prev.gst,
                            cgst: {
                              ...prev.gst.cgst,
                              [values.gst.cgst.percentage_bool === 1 ? 'percentage' : 'amount']: e.target.value,
                            },
                          },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{values.gst.cgst.percentage_bool === 1 ? '%' : '₹'}</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />

                  {/* Repeat for SGST, IGST, and CESS */}
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    SGST
                  </Typography>
                  <PercentageSwitch
                    checked={values.gst.sgst.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: { ...prev.gst, sgst: { ...prev.gst.sgst, percentage_bool: e.target.checked ? 1 : 0 } },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                  />
                  <TextField
                    label={values.gst.sgst.percentage_bool === 1 ? 'Percentage (%)' : 'Amount'}
                    value={values.gst.sgst.percentage_bool === 1 ? values.gst.sgst.percentage : values.gst.sgst.amount}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: {
                            ...prev.gst,
                            sgst: {
                              ...prev.gst.sgst,
                              [values.gst.sgst.percentage_bool === 1 ? 'percentage' : 'amount']: e.target.value,
                            },
                          },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{values.gst.sgst.percentage_bool === 1 ? '%' : '₹'}</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />

                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    IGST
                  </Typography>
                  <PercentageSwitch
                    checked={values.gst.igst.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: { ...prev.gst, igst: { ...prev.gst.igst, percentage_bool: e.target.checked ? 1 : 0} },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                  />
                  <TextField
                    label={values.gst.igst.percentage_bool === 1 ? 'Percentage (%)' : 'Amount'}
                    value={values.gst.igst.percentage_bool === 1 ? values.gst.igst.percentage : values.gst.igst.amount}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: {
                            ...prev.gst,
                            igst: {
                              ...prev.gst.igst,
                              [values.gst.igst.percentage_bool === 1 ? 'percentage' : 'amount']: e.target.value,
                            },
                          },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{values.gst.igst.percentage_bool === 1 ? '%' : '₹'}</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />

                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    CESS
                  </Typography>
                  <PercentageSwitch
                    checked={values.gst.cess.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: { ...prev.gst, cess: { ...prev.gst.cess, percentage_bool: e.target.checked ? 1 : 0 } },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                  />
                  <TextField
                    label={values.gst.cess.percentage_bool === 1? 'Percentage (%)' : 'Amount'}
                    value={values.gst.cess.percentage_bool === 1 ? values.gst.cess.percentage : values.gst.cess.amount}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          gst: {
                            ...prev.gst,
                            cess: {
                              ...prev.gst.cess,
                              [values.gst.cess.percentage_bool === 1 ? 'percentage' : 'amount']: e.target.value,
                            },
                          },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{values.gst.cess.percentage_bool === 1 ? '%' : '₹'}</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />

                  {/* Other Tax */}
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Other Tax
                  </Typography>
                  <PercentageSwitch
                    checked={values.other_tax.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          other_tax: {
                            ...prev.other_tax,
                            other_tax: { ...prev.other_tax, percentage_bool: e.target.checked ? 1 : 0 },
                          },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                  />
                  <TextField
                    label={values.other_tax.percentage_bool === 1 ? 'Percentage (%)' : 'Amount'}
                    value={values.other_tax.percentage_bool === 1 ? values.other_tax.percentage : values.other_tax.amount}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          other_tax: {
                            ...prev.other_tax,
                            [values.other_tax.percentage_bool === 1 ? 'percentage' : 'amount']: e.target.value,
                          },
                        };
                        calculateTotal();
                        return newValue;
                      })
                    }
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{values.other_tax.percentage_bool === 1 ? '%' : '₹'}</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <Typography variant="h6" align="center" gutterBottom>
        Total Amount: ₹{indianFormatNumber(total)}
      </Typography>
      <DialogActions>
        <Button onClick={() => closeDialog(false)}>Cancel</Button>
        <Button onClick={handleApplyToAll} variant="contained">
          Apply to All
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogGSTDiscountAll;
