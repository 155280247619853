import {
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { decryptToken } from '../../components/security/securityFunctions';
import { apiCall } from '../../components/functions/apiCall';
import { API_SIGN, FETCH_CATEGORY, FETCH_LEDGERS, FETCH_WALLET } from '../../variables/api-variables';
import TransactionFilter from './fetchTransactions/TransactionFilters';

const DisplayTransaction = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  // console.log(companyData);
  const companyId = urlSearchParams.get('company_id')
  const [searchString, setSearchString] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [throughOptions, setThroughOptions] = useState([]);
  const [ledgerOptions, setLedgerOptions] = useState([]);


  const fetchCategories = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
    setShowLoader(true);
    try {
      // API request for fetch company
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'fetch_ledger_categories',
        company_id: companyId,
        jwt_token: userToken,
        search_string: searchStringForce || searchString,
        limit: 'all',
      });
      apiCall(inputData, 'POST', FETCH_CATEGORY, 'plain/text')
        .then((response) => {
          setShowLoader(false);
          if (response.data.status === 1) {
            setCategoryOptions(response.data.response.category_info);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchThrough = async () => {
    const inputData = JSON.stringify({
      function_type: 'fetch_payment_wallets',
      company_id: companyId,
      search_string: '',
      api_signature: API_SIGN,
      jwt_token: userToken,
    });
    apiCall(inputData, 'POST', FETCH_WALLET, 'plain/text').then((response) => {
      if (response.data.status === 1) {
        const throughInfo = response.data.response.payment_info;
        if (throughInfo.length > 0) {
          const throughList = throughInfo.map((through) => ({
            id: through.id,
            label: `${through.name}`,
          }));
          setThroughOptions(throughList);
        } else {
          console.log(response);
        }
      }
    });
  };

  const [maxReachedLedgersString, setMaxReachedLedgersString] = useState(false);
  const fetchLedgers = async (searchString = '') => {
    let searchFurtherApi = 1;
    if (maxReachedLedgersString !== false) {
      if (Array.isArray(maxReachedLedgersString)) {
        // check here before proceeding and checking
        if (maxReachedLedgersString.some(str => str.startsWith(searchString))) {
          searchFurtherApi = 0;
        }
      }
    }
    if (searchFurtherApi > 0) {
      try {
        const inputData = JSON.stringify({
          function_type: 'fetch_ledgers',
          company_id: companyId,
          search_string: searchString,
          api_signature: API_SIGN,
          jwt_token: userToken,
        });

        apiCall(inputData, 'POST', FETCH_LEDGERS, 'plain/text')
          .then((response) => {
            if (response.data.status === 1) {
              if (response.data.response.max_reached === 1) {
                if (!maxReachedLedgersString) {
                  setMaxReachedLedgersString([searchString])
                } else { setMaxReachedLedgersString([...maxReachedLedgersString, searchString]) };
              }

              // Merge the arrays
              let mergedArray = ledgerOptions.concat(response.data.response.ledgers_info);

              // Filter out duplicate objects based on ID
              let uniqueArray = mergedArray.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                  t.id === obj.id
                ))
              );
              setLedgerOptions(uniqueArray);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.error('Error fetching ledgers:', error.message);
          });
      } catch (error) {
        console.error('Error fetching ledgers:', error.message);
      }
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchThrough();
    fetchLedgers();
  }, []);


  return (
    <>
      <TransactionFilter companyId={companyId} handleSearch={null} categoryOptions={categoryOptions} ledgerOptions={ledgerOptions} throughOptions={throughOptions} userToken={userToken} fetchLedgers={fetchLedgers} />
    </>
  );
};

export default DisplayTransaction;