import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  CircularProgress,
  useMediaQuery,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SvgIcon,
  TextField,
  Typography,
  Card,
  Button,
  Fab,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useRef, useState } from 'react';
import { Download, ExpandLess, ExpandMore } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { decryptToken } from '../../components/security/securityFunctions';
import NoData from '../../components/no_data/NoData';
import { API_SIGN, FETCH_FILES_CATEGORY, UPLOAD_FILES, VIEWS_DRIVE_FILES } from '../../variables/api-variables';
import Notification from './notification/Notification';
import { apiCall } from '../../components/functions/apiCall';
import DataView from '../../components/my_files/componentforViewFile/DataView';
import palette from '../../theme/palette';
import { ShallowRouting } from '../../components/routing/ShallowRouting';

const validationSchema = Yup.object({
  files: Yup.mixed()
    .required('File is required')
    .test('fileType', 'Invalid file type', (value) => {
      if (!value) {
        return true; // If no file is selected, validation passes
      }

      const allowedFileTypes = [
        'image/*',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'application/vnd.ms-excel',
      ];
      return allowedFileTypes.some((type) => value?.type?.includes(type));
    }),
});

const ViewDriveFiles = ({
  openAddFolderOrFile,
  setOpenAddFolderOrFile,
  setOpenEditFile,
  viewType,
  setViewType,
  reminderDate,
  documentDate,
  setDocumentDate,
  setReminderDate,
  terminationDate,
  setTerminationDate,
  showAdvancedFilters,
  setShowAdvancedFilters,
  searchString,
  setSearchString,
  searchCategory,
  setSearchCategory,

  handleButtonUploadBack,
}) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const fileInputRef = useRef(null);
  const [filePreview, setFilePreview] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  // const companyId = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}').company.company_id;
  const [isDragging, setIsDragging] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  let companyId = urlSearchParams.get('company_id');
  if (userToken.workflow_type === 'individual') {
    if (!companyId || companyId === '') {
      companyId = userToken.company_id;
    }
  }
  const [page, setPage] = useState(0);
  const [categoryList, setCategoryList] = useState([]);

  const isMobileScreen = useMediaQuery('(max-width:475px)');
  // const [searchString, setSearchString] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(
    new URLSearchParams(window.location.search).has('offset')
      ? new URLSearchParams(window.location.search).get('offset')
      : 15
  );
  const [dataViewKey, setDataViewKey] = useState(1);
  const [selectedValue, setSelectedValue] = useState('1');
  const [length, setLength] = useState(0);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleClickQuickUpload = () => {
    fileInputRef.current.click();
  };
  const viewDriveItems = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
    setIsLoading(true);
    try {
      const inputData = JSON.stringify({
        function_type: 'view_drive_items',
        api_signature: API_SIGN,
        jwt_token: userToken,
        reminder_date: reminderDate,
        date_of_document: documentDate,
        termination_document_date: terminationDate,
        company_id: companyId,
        limit: rowsPerPageForce || rowsPerPage,
        page: !pageForce ? page + 1 : parseInt(pageForce, 10) + 1,
        search_string: searchStringForce || searchString,
        search_in_company_filter: selectedValue,
        category_id: searchCategory,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', VIEWS_DRIVE_FILES, 'plain/text')
        .then((response) => {
          // console.log(response);
          if (response.data.status === 1) {
            setData(response.data.response.data.files_info);
            setLength(response.data.response.data.total_rows);
            setDataViewKey(dataViewKey + 1);
          } else {
            enqueueSnackbar(response.data.response, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handlePageChange = (e, value) => {
    // console.log(value)
    const existingUrlSearchParams =
      urlSearchParams.toString() !== '' && urlSearchParams.toString() !== null
        ? JSON.parse(`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => {
            return key === '' ? value : decodeURIComponent(value);
          })
        : null;
    setPage(value);
    navigate(ShallowRouting({ ...existingUrlSearchParams, page: value }), { replace: true });
    viewDriveItems(null, null, value.toString());
    // setPage(value);
  };

  const handleRowsPerPageChange = (value) => {
    if (value !== rowsPerPage && [15, 25, 50].includes(value)) {
      const existingParams = Object.fromEntries(urlSearchParams.entries());
      const updatedParams = { ...existingParams, limitRows: value };
      navigate(ShallowRouting(updatedParams), { replace: true });
      setRowsPerPage(value);
      setPage(0);
      viewDriveItems(null, value, '0');
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const pageParam = urlSearchParams.get('page');
    setPage(pageParam !== null ? parseInt(pageParam, 10) : 0);
  }, []);

  const handleSearchClick = () => {
    viewDriveItems();
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    const newUrlSearchParams = new URLSearchParams(window.location.search);
    setSearchString(value);
  };

  useEffect(() => {
    const payload = JSON.stringify({
      function_type: 'fetch_upload_categories',
      company_id: Number(companyId),
      search_string: '',
      api_signature: API_SIGN,
      jwt_token: userToken,
    });
    apiCall(payload, 'POST', FETCH_FILES_CATEGORY, 'plain/text')
      .then((response) => {
        setCategoryList(response.data.response.category_info);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const toggleAdvancedFilters = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  const handleUploadProgress = (progressEvent) => {
    // console.log(progressEvent)
    const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(uploadProgress);
    return true;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      files: null,
      filename: '',
      tag: [],
      date_of_document: '',
      reminder_date: '',
      termination_document_date: '',
      share_mode: 'all',
      share_with: [],
      company_id: Number(companyId),
      party_1: '',
      party_2: '',
      party_3: '',
      let_others_delete: 1,
      let_others_edit: 1,
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        const inputData = {
          function_type: 'upload_to_drive',
          filesattached: values.files,
          filename: values.filename,
          tags: null,
          date_of_document: '',
          reminder_date: '',
          termination_document_date: '',
          share_mode: 'private',
          share_with: null,
          party_1: '',
          party_2: '',
          party_3: '',
          let_others_delete: 1,
          let_others_edit: 1,
          company_id: Number(companyId),
          // category_id: null,

          api_signature: API_SIGN,
          jwt_token: userToken,
        };
        console.log(inputData);
        const formData = new FormData();

        Object.keys(inputData).forEach((key) => {
          formData.append(key, inputData[key]);
        });
        apiCall(formData, 'POST', UPLOAD_FILES, 'multipart/form-data', 'application/json', handleUploadProgress)
          .then((response) => {
            console.log(response);
            const responseData = JSON.parse(response.data);
            if (responseData.status === 1) {
              enqueueSnackbar(responseData.response.successmsg, {
                variant: 'success',
                autoHideDuration: 2000,
              });
              viewDriveItems();
            }
          })
          .catch((err) => {
            // console.log(err);
            enqueueSnackbar('Error: '.err?.message, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          });
      } catch (error) {
        // console.log(error);
        enqueueSnackbar('Error:'.error?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
  });

  useEffect(() => {
    viewDriveItems();
  }, [page, rowsPerPage, searchString, selectedValue, searchCategory, reminderDate, terminationDate, documentDate]);

  const handleDrop = (files) => {
    const file = files[0];
    const fileName = file.name;
    const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, ''); // Remove extension from name
    console.log('handleDrop running');
    formik.setFieldValue('files', file);
    formik.setFieldValue('filename', nameWithoutExtension);
    setFilePreview(URL.createObjectURL(file));
    setNotifications((prev) => [...prev, ...files]);
    formik.submitForm();
    viewDriveItems();
  };

  console.log(formik.values);

  const handleFileInputChange = (event) => {
    console.log(event);
    const files = event.target.files;
    if (files?.length > 0) {
      console.log('here in input change');
      handleDrop(files);
    }
  };
  useEffect(() => {
    const handleDragOver = (e) => e.preventDefault();

    const handleDrop = async (e) => {
      e.preventDefault();
      console.log('here in 1');
      const files = e.dataTransfer.files;
      if (files?.length > 0) {
        console.log('here in 2');

        const file = files[0];
        const fileName = file.name;
        const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');

        // Set Formik values for the file and other related information
        await formik.setFieldValue('files', file);
        await formik.setFieldValue('filename', nameWithoutExtension);

        formik.submitForm();
        viewDriveItems();
      }
    };

    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
    };
  }, [formik.values]);

  const dragOverStyle = {
    background: 'rgba(173, 216, 230, 0.3)',
    border: '2px dashed #aaaaaa',
    color: '#000',
    width: '100%',
    height: '500px',
    textAlign: 'center',
    justifyItems: 'center',
  };

  const defaultStyle = {
    margin: 'auto',
    width: '100%',
    height: '100%',
    padding: '16px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Grid container>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '999',
        }}
        onClick={handleClickQuickUpload}
      >
        <UploadIcon />
      </Fab>
      <Grid item xs={12} sm={12} md={12}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="start">
            <Grid item xs={10} md={11}>
              <Grid container style={{ padding: '0px' }} spacing={2}>
                <Grid item xs={12} md={4}>
                  <OutlinedInput
                    size={isMobileScreen ? 'small' : 'medium'}
                    value={searchString}
                    fullWidth
                    placeholder="Search"
                    startAdornment={
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    }
                    onChange={handleSearch}
                  />
                </Grid>

                {showAdvancedFilters && (
                  <>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ '& label.Mui-focused': { background: 'white' } }}>
                        <InputLabel id="category-select-label">Category</InputLabel>
                        <Select
                          size={isMobileScreen ? 'small' : 'medium'}
                          labelId="category-select-label"
                          id="category-select"
                          value={searchCategory}
                          label="Category"
                          name="category"
                          onChange={(e) => setSearchCategory(e.target.value)}
                        >
                          <MenuItem value="all">All</MenuItem>
                          {categoryList?.map((item) => (
                            <MenuItem key={item.category_id} value={item.category_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        size={isMobileScreen ? 'small' : 'medium'}
                        label="Document date"
                        name="date_of_document"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={documentDate}
                        onChange={(e) => setDocumentDate(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size={isMobileScreen ? 'small' : 'medium'}
                        label="Termination date"
                        name="termination_document_date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={terminationDate}
                        onChange={(e) => setTerminationDate(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size={isMobileScreen ? 'small' : 'medium'}
                        label="Reminder date"
                        name="reminder_date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={reminderDate}
                        onChange={(e) => setReminderDate(e.target.value)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2} md={1} flexDirection={'row'} justifyContent={'right'}>
              <Button
                size={isMobileScreen ? 'small' : 'medium'}
                variant="text"
                style={{ color: palette.grey[500] }}
                onClick={toggleAdvancedFilters}
              >
                {!isMobileScreen ? (!showAdvancedFilters ? 'More' : 'Hide') : ''}&nbsp;
                {!showAdvancedFilters ? <ExpandMore /> : <ExpandLess />}
              </Button>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ mt: '10px' }}>
          <div>
            <div
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDragEnter={() => setIsDragging(true)}
              onDragLeave={() => setIsDragging(false)}
              onDrop={(e) => {
                e.preventDefault();
                setIsDragging(false);
                const droppedFiles = e.dataTransfer.files;
                handleDrop(droppedFiles);
              }}
              style={isDragging ? { ...dragOverStyle } : defaultStyle}
            >
              {isDragging && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h3" gutterBottom>
                    Drop your file here
                  </Typography>
                  <Download style={{ fontSize: '3rem' }} />
                </div>
              )}
              <input
                type="file"
                accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              {isLoading ? (
                <CircularProgress size={20} />
              ) : !isDragging && data.length > 0 ? (
                <DataView
                  key={dataViewKey}
                  data={data}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  openAddFolderOrFile={openAddFolderOrFile}
                  setOpenAddFolderOrFile={setOpenAddFolderOrFile}
                  rowsPerPage={rowsPerPage}
                  length={length}
                  viewType={viewType}
                  onSetViewType={setViewType}
                  setOpenEditFile={setOpenEditFile}
                  viewDriveItems={viewDriveItems}
                  handleButtonUploadBack={handleButtonUploadBack}
                />
              ) : isDragging ? null : (
                <NoData />
              )}

              {notifications.length > 0 && <Notification files={notifications} progress={progress} />}
            </div>

            {formik.touched.files && formik.errors.files && (
              <Typography variant="caption" color="error">
                {formik.errors.files}
              </Typography>
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewDriveFiles;
