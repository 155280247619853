import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react'
import { Card, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Stack, TextField, Button, InputAdornment, IconButton, Box, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import { API_SIGN, FETCH_FILES_CATEGORY, UPDATE_DRIVE_FILES, UPLOAD_FILES } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import { decryptToken } from '../../components/security/securityFunctions';

const UploadMyFiles = ({ openEditFile, shortCutToSelectFile }) => {
  const [initialFileValue, setInitialFileValue] = useState({ ...openEditFile });
  const [filePreview, setFilePreview] = useState(initialFileValue?.key || null);
  const { enqueueSnackbar } = useSnackbar();
  const [tagInput, setTagInput] = useState("");
  const [isCreate, setIsCreate] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const fileInputRef = useRef(null);
  const [showInitialFileOnEdit, setShowIntialFileOnEdit] = useState(true);
  const [changeLogo, setChangeLogo] = useState(1);
  const [categoryList, setCategoryList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const location = useLocation();
  const urlSearchparams = new URLSearchParams(location.search)
  const companyId = urlSearchparams.get('company_id');

  const [progress, setProgress] = useState(0);

  const navigate = useNavigate()

  const handleDelete = (tagToDelete) => {
    formik.setFieldValue(
      "tags",
      formik.values.tags.filter((tag) => tag !== tagToDelete)
    );
  };
  // this has to called when shorcut key is clicked
  useEffect(() => {
    if (shortCutToSelectFile) {
      handleDropZoneClick()
    }
  }, [shortCutToSelectFile])
  // it has to click auto maticaly
  const handleDropZoneClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (openEditFile) {
      setIsCreate(false);
    }
  }, []);

  const handleFileChange = (file) => {
    // const fileList = Array.from(file);
    setShowIntialFileOnEdit(false);
    formik.setErrors({ files: [] })
    // if (file.length > 10) {
    //   formik.setErrors({
    //     files: ['You can upload maximum 10 files'],
    //   });
    //   return;
    // }
    console.log(file[0])

    // let totalFileSize = 0;

    // file.map((item) => {
    //   totalFileSize += parseFloat(item.size)
    //   console.log(totalFileSize)
    //   return totalFileSize
    // })


    // if (file.length < 10 && totalFileSize < 2500000) {


    const fileName = file[0].name;
    console.log(fileName) // here has to work for acessing all name of the file
    /* eslint-disable no-bitwise */
    const fileExtension = fileName?.slice(Math.max(fileName.lastIndexOf("."), 0) + 1);
    /* eslint-enable no-bitwise */
    const nameWithoutExtension = fileName?.replace(/\.[^/.]+$/, "");
    formik.setFieldValue("files", isCreate ? file : file[0]);
    formik.setFieldValue("fileExtension", fileExtension);
    setFilePreview(URL.createObjectURL(file[0]));
    // console.log(nameWithoutExtension)
    formik.setFieldValue("filename", nameWithoutExtension);
    if (!formik.values.filename || formik.values.filename.trim() === "") {
      formik.setFieldValue("filename", nameWithoutExtension);
    }
  };

  const handleDrop = (files) => {
    const filesArray = Array.from(files);
    formik.setFieldValue("files", filesArray);
    console.log(files)
    if (files.length > 0) {
      const file = files[0];
      handleFileChange(file);
    }
  };

  const handleFileInputChange = (event) => {
    console.log(event)
    setShowIntialFileOnEdit(false);
    setChangeLogo(0);
    // console.log(event)

    if (event.target.files && event.target.files.length > 0) {
      const filesArray = isCreate ? Array.from(event.target.files) : event.target.files;
      handleFileChange(filesArray);
      // const file = event.target.files[0];
      console.log(filesArray)
      // handleFileChange(file);
    }
  };

  const TagChip = ({ label, onDelete }) => {
    return (
      <Chip
        label={label}
        onDelete={onDelete}
        color="primary"
        deleteIcon={<CloseIcon />}
        style={{ marginTop: "-2px", marginBottom: "17px" }}
      />
    );
  };

  const handleAddTag = () => {
    const newTag = tagInput.trim();
    if (newTag && !formik.values.tags?.includes(newTag)) {
      formik.setFieldValue("tags", [...formik.values.tags, newTag]);
      setTagInput("");
    }
  };

  const handleUploadProgress = (progressEvent) => {
    const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // console.log(uploadProgress);
    setProgress(uploadProgress);
    return true;
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      files: initialFileValue?.key || null,
      filename: initialFileValue?.filename || "",
      tags: initialFileValue?.tags || [],
      date_of_document: initialFileValue?.date_of_document || "",
      reminder_date: initialFileValue?.reminder_date || "",
      termination_document_date: initialFileValue?.termination_document_date || "",
      share_mode: initialFileValue?.share_mode || "",
      share_with: initialFileValue?.share_with || [],
      party_1: initialFileValue?.party_1 || "",
      party_2: initialFileValue?.party_2 || "",
      party_3: initialFileValue?.party_3 || "",
      category: initialFileValue.category_id || "",
      let_others_delete: initialFileValue?.let_others_delete || "",
      let_others_edit: initialFileValue?.let_others_edit || "",
    },
    validationSchema: Yup.object({
      files: Yup.mixed().required("File is required")

    }),
    onSubmit: (values) => {
      // console.log(formik.values)
      try {
        const inputData = {
          function_type: isCreate ? "upload_to_drive" : "update_to_drive_specific_file",
          file_id: initialFileValue?.id || "",
          filesattached: isCreate ? values.files : values.files,
          company_id: Number(companyId),
          category_id: values.category,
          filename: values.filename,
          tags: JSON.stringify(values.tags) ? JSON.stringify(values.tags) : null,
          date_of_document: values.date_of_document,
          reminder_date: values.reminder_date,
          termination_document_date: values.termination_document_date,
          share_mode: "private",
          share_with: JSON.stringify(values.share_with) ? JSON.stringify(values.share_with) : null,
          party_1: values.party_1,
          party_2: values.party_2,
          party_3: values.party_3,
          remove_logo: initialFileValue?.id && changeLogo ? 0 : 1,
          let_others_delete: 1,
          let_others_edit: 1,

          api_signature: API_SIGN,
          jwt_token: userToken,
        };
        // console.log(inputData)
        const formData = new FormData();
        Object.keys(inputData).forEach((key) => {
          if (Array.isArray(inputData[key])) {
            inputData[key].forEach((file, index) => {
              formData.append(`${key}[${index}]`, file);
            });
          } else {

            formData.append(key, inputData[key]);
          }
        });
        setIsLoading(true);
        apiCall(
          formData,
          "POST",
          isCreate ? UPLOAD_FILES : UPDATE_DRIVE_FILES,
          "multipart/form-data",
          "application/json",
          handleUploadProgress
        )
          .then((response) => {
            console.log(response);
            const responseData = JSON.parse(response.data);
            if (responseData.status === 1) {
              // console.log(response);
              enqueueSnackbar(responseData.response.successmsg, {
                variant: "success",
                autoHideDuration: 2000,
              });
              setIsLoading(false)
              navigate(`/dashboard/displayfiles?company_id=${companyId}`, { state: { allowBack: true } })
            } else {
              enqueueSnackbar(`Upload failed. ${responseData.response}`, { variant: "error", autoHideDuration: 2000 });
              setIsLoading(false)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    },
  });
  useEffect(() => {
    fetchCategories();

  }, [])


  const fetchCategories = () => {
    const payload = JSON.stringify({
      function_type: "fetch_upload_categories",
      company_id: Number(companyId),
      search_string: "",
      api_signature: API_SIGN,
      jwt_token: userToken

    })
    apiCall(payload, "POST", FETCH_FILES_CATEGORY, 'plain/text').then((response) => {
      setCategoryList(response.data.response.category_info);
      if (formik.values.category === '' || formik.values.category === null) {
        response.data.response.category_info?.forEach(element => {
          if (element.name === 'Others') {
            formik.setFieldValue('category', element.category_id);
          }
        });
      }
    }).catch((err) => {
      console.log(err)
    })
  }



  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ margin: 2, padding: 2 }}>
              <div style={{ margin: "auto", width: "100%", marginBottom: "16px" }}>
                <Box
                  onClick={handleDropZoneClick}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    const droppedFiles = e.dataTransfer.files;
                    handleDrop(droppedFiles);
                  }}
                  style={{
                    margin: "auto",
                    width: "80%",
                    height: "200px",
                    border: "2px dashed #aaaaaa",
                    borderRadius: "4px",
                    padding: "16px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  <input
                    type="file"
                    name="files"
                    multiple={isCreate ? true : false}
                    onChange={handleFileInputChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  {showInitialFileOnEdit && initialFileValue?.file_extension ? (
                    <>
                      {["jpg", "png", "jpeg", "webp"].includes(initialFileValue?.file_extension) ? (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={initialFileValue?.key}
                            alt="Preview"
                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                          />
                          {isHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0%",
                                left: "98%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "rgba(110, 110, 110, 0.5)",
                              }}
                            // onClick={}
                            >
                              <EditIcon style={{ color: "red" }} />
                            </IconButton>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {["pdf"].includes(initialFileValue?.file_extension) && (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src="/assets/logos/LogoPdf.png"
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                            alt=""
                          />
                        </div>
                      )}
                      {["xls", "xlsx"].includes(initialFileValue?.file_extension) && (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src="/assets/logos/excelLogo.png"
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              backgroundColor: "white",
                            }}
                            alt=""
                          />
                        </div>
                      )}
                      {["doc", "docx"].includes(initialFileValue?.file_extension) && (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src="/assets/logos/docxlogo.png"
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              backgroundColor: "white",
                            }}
                            alt=""
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                  {showInitialFileOnEdit && initialFileValue?.file_extension ? (
                    <Typography variant="body2" sx={{ marginLeft: "5px" }}>
                      {initialFileValue.filename ? `${initialFileValue.filename}.${initialFileValue.file_extension}` : ""}

                    </Typography>
                  ) : null}
                  {filePreview ? (
                    <Box>

                      {["jpg", "png", "jpeg", "webp"].includes(formik.values.fileExtension) ? (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={filePreview}
                            alt="Preview"
                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                          />
                          {isHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0%",
                                left: "98%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "rgba(110, 110, 110, 0.5)",
                              }}
                            // onClick={}
                            >
                              <EditIcon style={{ color: "red" }} />
                            </IconButton>
                          )}
                        </div>
                      ) : (
                        ""
                      )}


                      {formik.values.fileExtension === "xlsx" && (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src="/assets/logos/excelLogo.png"
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              backgroundColor: "white",
                            }}
                            alt=""
                          />
                          {isHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0%",
                                left: "98%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "rgba(110, 110, 110, 0.5)",
                              }}
                            // onClick={}
                            >
                              <EditIcon style={{ color: "red" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      {formik.values.fileExtension === "pdf" && (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src="/assets/logos/LogoPdf.png"
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                            alt=""
                          />
                          {isHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0%",
                                left: "98%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "rgba(110, 110, 110, 0.5)",
                              }}
                            // onClick={}
                            >
                              <EditIcon style={{ color: "red" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      {formik.values.fileExtension === "docx" && (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src="/assets/logos/docxlogo.png"
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                            alt=""
                          />
                          {isHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0%",
                                left: "98%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "rgba(110, 110, 110, 0.5)",
                              }}
                            // onClick={}
                            >
                              <EditIcon style={{ color: "red" }} />
                            </IconButton>
                          )}
                        </div>
                      )}

                    </Box>
                  ) : (
                    <>
                      <IconButton color="primary">
                        <CloudUploadIcon sx={{ height: "100px", width: "100px" }} />
                      </IconButton>

                    </>
                  )}
                  {filePreview ? (
                    <Typography variant="body2" sx={{ marginLeft: "5px" }}>
                      <>
                        {formik.values.files ? formik.values.files[0]?.name : ""}
                        {!showInitialFileOnEdit && !initialFileValue?.file_extension && formik?.values?.files?.length > 1 ? ` and ${formik.values.files.length - 1} More` : ''}
                      </>
                    </Typography>
                  ) : (
                    <Typography variant="h6" style={{ color: "#616565" }}>
                      Drop or upload your file here
                    </Typography>
                  )}

                  {formik.touched.files && formik.errors.files && (
                    <Typography variant="caption" color="error">
                      {formik.errors.files}
                    </Typography>
                  )}
                </Box>
              </div>
              {filePreview ? (
                <TextField
                  variant="outlined"
                  label="Title"
                  name="filename"
                  fullWidth
                  sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                  error={!!(formik.touched.filename && formik.errors.filename)}
                  helperText={formik.touched.filename && formik.errors.filename}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.filename}
                />
              ) : null}
              <TextField
                variant="outlined"
                label=" Add Tag"
                name="tags"
                value={tagInput}
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                onChange={(e) => setTagInput(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: "10px" }}>
                      <IconButton
                        edge="end"
                        onClick={handleAddTag}
                        sx={{ background: "#00f", color: "white" }}
                      >
                        <AddIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                InputLabelProps={{
                  htmlFor: "tag-field",
                  shrink: true,
                }}
              />
              {formik.values.tags?.length > 0 && (
                <div
                  style={{
                    display: "inline-block",
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%",
                    padding: "10px",
                  }}
                >
                  {formik.values.tags.map((tag, index) => {
                    return (
                      <span key={index} style={{ width: "auto", padding: "2px", margin: "2px" }}>
                        <TagChip label={tag} onDelete={() => handleDelete(tag)} />
                      </span>
                    );
                  })}
                </div>
              )}
              <FormControl
                fullWidth
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
              >
                <InputLabel id="category-select">Category</InputLabel>
                <Select
                  labelId="category-select"
                  id="demo-simple-select1"
                  variant="outlined"
                  label="Category"
                  name="category"
                  error={!!(formik.touched.category && Boolean(formik.errors.category))}
                  onChange={(e) => {
                    formik.setFieldValue("category", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  fullWidth
                  sx={{ "& label.Mui-focused": { background: "white" } }}
                  value={formik.values.category || "1"}
                >
                  {categoryList?.map((item) => <MenuItem key={item.category_id} value={item.category_id}> {item.name} </MenuItem>)}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                label=" Reminder Date"
                name="reminder_date"
                type="date"
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                error={!!(formik.touched.reminder_date && formik.errors.reminder_date)}
                helperText={formik.touched.reminder_date && formik.errors.reminder_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.reminder_date}
              />
              <TextField
                variant="outlined"
                label="Date Of Document"
                name="date_of_document"
                type="date"
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                error={!!(formik.touched.date_of_document && formik.errors.date_of_document)}
                helperText={formik.touched.date_of_document && formik.errors.date_of_document}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.date_of_document}
              />
              <TextField
                variant="outlined"
                label="Termination Date"
                name="termination_document_date"
                type="date"
                error={
                  !!(
                    formik.touched.termination_document_date &&
                    formik.errors.termination_document_date
                  )
                }
                helperText={
                  formik.touched.termination_document_date &&
                  formik.errors.termination_document_date
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                value={formik.values.termination_document_date}
              />
              <TextField
                variant="outlined"
                label="Party 1"
                name="party_1"
                error={!!(formik.touched.party_1 && formik.errors.party_1)}
                helperText={formik.touched.party_1 && formik.errors.party_1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                value={formik.values.party_1}
              />
              <TextField
                variant="outlined"
                label="Party 2"
                name="party_2"
                error={!!(formik.touched.party_2 && formik.errors.party_2)}
                helperText={formik.touched.party_2 && formik.errors.party_2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                value={formik.values.party_2}
              />
              <TextField
                variant="outlined"
                label="Party 3"
                name="party_3"
                error={!!(formik.touched.party_3 && formik.errors.party_3)}
                helperText={formik.touched.party_3 && formik.errors.party_3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ margin: "8px", "& label.Mui-focused": { background: "white" } }}
                value={formik.values.party_3}
              />
            </Stack>

            <Button
              sx={{ margin: 2, justifyContent: "flex-end", float: "right", }}
              type="submit"
              variant="contained"
              disabled={isLoading}

            >
              {isLoading ? (progress <= 99 ? <> {`${progress}%`}<CircularProgress size="15px" /></> : '') : null}
              {isCreate ? "Submit" : "Update"}
            </Button>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
}

export default UploadMyFiles