import { useEffect, useState } from 'react';
import {
  useMediaQuery,
  Box,
  Button,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import { API_SIGN, FETCH_DASHBOARD_STATS } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import { SkeletonLoaderSingleForm } from '../../components/skeleton_loader/skeletonLoader';
import palette from '../../theme/palette';
import { indianFormatNumber } from '../../components/functions/data_functions';
import DashboardChart from './DashboardChart';
import { decryptToken } from '../../components/security/securityFunctions';

const DashboardStats = ({ userToken, defaultCompany = 'all' }) => {
  const userRole = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').role;
  const viewCashbookDays = JSON.parse(
    decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}'
  ).view_cashbook_days;
  const viewFilesUploaded = JSON.parse(
    decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}'
  ).view_files_uploaded;
  const [showLoaderDashboard, setShowLoaderDashboard] = useState(false);
  const [showCreateCompany, setShowCreateCompany] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);

  const [selectedCompanyId, setSelectedCompanyId] = useState(defaultCompany);

  const [receiptTotal, setReceiptTotal] = useState('0.00');
  const [paymentTotal, setPaymentTotal] = useState('0.00');
  const [filesUploaded, setFilesUploaded] = useState('0');
  const [reminderFiles, setReminderFiles] = useState('0');

  const todayDate = new Date();

  const [todayformattedDate, setTodayFormattedDate] = useState(
    `${todayDate.getFullYear()}-${String(todayDate.getMonth() + 1).padStart(2, '0')}-${String(
      todayDate.getDate()
    ).padStart(2, '0')}`
  );

  const [selectedMinDate, setSelectedMinDate] = useState(todayformattedDate);

  const handleMinDateChange = (e) => {
    setSelectedMinDate(e.target.value);
  };
  const [selectedMaxDate, setSelectedMaxDate] = useState(todayformattedDate);

  const handleMaxDateChange = (e) => {
    setSelectedMaxDate(e.target.value);
  };

  const getMinDate = () => {
    const currentDate = new Date();
    const noOfDaysAgo = new Date(currentDate);
    if (viewCashbookDays > viewFilesUploaded) {
      noOfDaysAgo.setDate(currentDate.getDate() - viewCashbookDays);
    } else {
      noOfDaysAgo.setDate(currentDate.getDate() - viewFilesUploaded);
    }
    return noOfDaysAgo.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const isMobileScreen = useMediaQuery('(max-width:475px)');

  const navigate = useNavigate();
  const handleCreateCompany = () => {
    navigate('/dashboard/companies');
  };
  const fetchDashboardStats = () => {
    setShowLoaderDashboard(true);
    try {
      // API request for fetch dashboard stats
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'dashboard_stats',
        jwt_token: userToken.userToken,
        company_id: selectedCompanyId,
        min_date_selected: selectedMinDate,
        max_date_selected: selectedMaxDate,
      });
      // console.log(inputData)
      apiCall(inputData, 'POST', FETCH_DASHBOARD_STATS, 'plain/text')
        .then((response) => {
          console.log(response);
          setShowLoaderDashboard(false);
          if (response.data.status === 1) {
            let resp = response.data.response;
            if (resp.company_info.length < 1) {
              setShowCreateCompany(true);
            } else {
              if (selectedCompanyId === 'all') {
                setCompanyOptions(resp.company_info);
              }
              setReceiptTotal(resp.receipt_total);
              setPaymentTotal(resp.payment_total);
              setFilesUploaded(resp.files_uploaded);
              setReminderFiles(resp.reminder_files);
            }
          } else {
            enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 15000 });
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDashboardStats();
  }, [selectedCompanyId, selectedMinDate, selectedMaxDate]);

  return showLoaderDashboard ? (
    <SkeletonLoaderSingleForm />
  ) : showCreateCompany ? (
    <Box sx={{ padding: '0px', margin: '20px 0px' }}>
      <Typography variant="h2" fontWeight={'500'} color={palette.primary.main} gutterBottom>
        Get Started
      </Typography>
      <Typography variant="body1" color={palette.grey[700]} gutterBottom>
        Create a company and get started.
      </Typography>
      <Button variant="contained" size="large" sx={{ margin: '10px 0px' }} onClick={handleCreateCompany}>
        Start Now
      </Button>
    </Box>
  ) : (
    <Box margin={'30px 0px'}>
      <Grid container padding={'0px'} spacing={1}>
        {defaultCompany !== 'all' ? null : (
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Company</InputLabel>
              <Select
                size="small"
                fullWidth
                labelId="select-label"
                id="select"
                value={selectedCompanyId}
                onChange={(event) => {
                  setSelectedCompanyId(event.target.value);
                }}
                label={'Company'}
                inputlabelprops={{
                  shrink: true,
                }}
              >
                <MenuItem value={'all'}>All</MenuItem>
                {companyOptions.map((element, index) => {
                  return (
                    <MenuItem key={`Company_${index}`} value={element.company_id}>
                      {element.company_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {userRole === 'other' ? (
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="Insights From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              // onChange={(e) => { setTodayFormattedDate(e.target.value) }}
              onChange={handleMinDateChange}
              sx={{ marginBottom: '15px' }}
              defaultValue={todayformattedDate}
              inputProps={{ min: getMinDate(), max: todayformattedDate }}
            />
            <TextField
              variant="outlined"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              // onChange={(e) => { setTodayFormattedDate(e.target.value) }}
              onChange={handleMaxDateChange}
              sx={{ marginBottom: '15px', marginLeft: '5px' }}
              defaultValue={todayformattedDate}
              inputProps={{ min: getMinDate(), max: todayformattedDate }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="Insights From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              onChange={(e) => {
                handleMinDateChange(e);
              }}
              sx={{ marginBottom: '15px' }}
              defaultValue={todayformattedDate}
              value={selectedMinDate}
            />
            <TextField
              variant="outlined"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              onChange={(e) => {
                handleMaxDateChange(e);
              }}
              sx={{ marginBottom: '15px', marginLeft: '5px' }}
              defaultValue={todayformattedDate}
              value={selectedMaxDate}
            />
          </Grid>
        )}
      </Grid>

      <Grid container padding={'20px 0px'} spacing={2}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              minWidth: 'auto',
              background: 'linear-gradient(135deg, rgba(38, 129, 41, 0.7), rgba(11, 102, 177, 1))',
              color: '#ffffff',
              borderRadius: '15px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
              },
            }}
          >
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ flex: 1, textAlign: 'center' }}>
                <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                  {receiptTotal ? indianFormatNumber(receiptTotal) : '0.00'}
                </Typography>
                <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500 }}>Net Receipts</Typography>
              </Box>
              <Box sx={{ flex: 1, textAlign: 'center' }}>
                <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                  {paymentTotal ? indianFormatNumber(paymentTotal) : '0.00'}
                </Typography>
                <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500 }}>Net Payments</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card
            sx={{
              minWidth: 'auto',
              background: 'linear-gradient(135deg, rgba(41, 126, 196, 1), rgba(215, 77, 210, 0.7))',
              color: '#ffffff',
              borderRadius: '15px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
              },
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}>
                {filesUploaded}
              </Typography>
              <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}>
                Files Uploaded
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card
            sx={{
              minWidth: 'auto',
              background: 'linear-gradient(135deg, rgba(221, 203, 42, 1), rgba(223, 131, 46, 1))',
              color: '#ffffff',
              borderRadius: '15px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
              },
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}>
                {reminderFiles}
              </Typography>
              <Typography sx={{ fontSize: isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}>
                Reminder Files
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DashboardChart userToken={userToken} selectedMinDate={selectedMinDate} selectedMaxDate={selectedMaxDate} selectedCompanyId={selectedCompanyId} />
    </Box>
  );
};

export default DashboardStats;
