import {
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  SvgIcon,
  TablePagination,
  Typography,
  useMediaQuery,
  Stack,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { API_SIGN, FETCH_COMPANY } from '../../variables/api-variables';
import { decryptToken } from '../../components/security/securityFunctions';
import { apiCall } from '../../components/functions/apiCall';
import { useHandleClick } from '../../components/list/use-handle-click';
import NoData from '../../components/no_data/NoData';
import { ShallowRouting } from '../../components/routing/ShallowRouting';
import palette from '../../theme/palette';
import DeleteSpecificCompany from './DeleteSpecificCompany';

const DisplayCompany = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  const [searchString, setSearchString] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [rows, setRowsChange] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(urlSearchParams.has('page') ? parseInt(urlSearchParams.get('page'), 10) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [companyToDelete, setCompanyToDelete] = useState('');
  const navigate = useNavigate();

  const handlePageChange = (e, value) => {
    const existingUrlSearchParams =
      urlSearchParams.toString() !== '' && urlSearchParams.toString() !== null
        ? JSON.parse(`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => {
            return key === '' ? value : decodeURIComponent(value);
          })
        : null;
    setPage(value);
    navigate(ShallowRouting({ ...existingUrlSearchParams, page: value }), { replace: true });
    fetchCompanies(null, null, value.toString());
    // setPage(value);
  };

  const handleRowsPerPageChange = (value) => {
    if (value !== rowsPerPage && [25, 50, 100].includes(value)) {
      const existingUrlSearchParams =
        (`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        (key, value) => {
          return key === '' ? value : decodeURIComponent(value);
        });
      navigate(ShallowRouting({ ...existingUrlSearchParams, limitRows: value }), { replace: true });
      setRowsPerPage(value);
      setPage(0);
      fetchCompanies(null, value, '0');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value.length >= 3) {
      fetchCompanies(value, rowsPerPage, '0');
      setSearchString(value === '' ? null : value);
    }
    if (value.length === 0) {
      fetchCompanies(value, rowsPerPage, '0');
      setSearchString(value === '' ? null : value);
    }
  };

  const handleEdit = (companyId, companyName, companyAddress, status) => {
    navigate('/dashboard/companies/addCompany', {
      state: {
        company_id: companyId,
        company_name: companyName,
        company_address: companyAddress,
        company_status: status,
      },
    });
  };

  const handleDelete = (companyId, companyName) => {
    setDeleteId(companyId);
    setCompanyToDelete(companyName);
    setOpenDeleteDialog(true);
  };

  const fetchCompanies = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
    setShowLoader(true);
    try {
      // API request for fetch company
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'fetch_companies',
        jwt_token: userToken.userToken,
        search_string: searchStringForce || searchString,
        limit: rowsPerPageForce || rowsPerPage,
        page: !pageForce ? page + 1 : parseInt(pageForce, 10) + 1,
      });
      // console.log(inputData)
      apiCall(inputData, 'POST', FETCH_COMPANY, 'plain/text')
        .then((response) => {
          // console.log(response);
          setShowLoader(false);
          if (response.data.status === 1) {
            setTotalRows(parseInt(response.data.response.total_rows, 10));
            const tempVar = response.data.response.company_info;
            setRowsChange([...tempVar]);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleClick = useHandleClick();

  const openCompany = (id) => {
    console.log(id);
    handleClick('/dashboard/companyDashboard', { company_id: id });
  };

  const isMobileScreen = useMediaQuery('(max-width:475px)');

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <OutlinedInput
              defaultValue=""
              fullWidth
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              }
              sx={{ maxWidth: 500 }}
              onChange={handleSearch}
            />
          </Card>
        </Grid>

        {openDeleteDialog && (
          <DeleteSpecificCompany
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            deleteId={deleteId}
            fetchCompanies={fetchCompanies}
            companyToDelete={companyToDelete}
          />
        )}

        {isMobileScreen ? (
          <Grid item xs={12} style={{ padding: '10px' }}>
            {showLoader ? (
              <CircularProgress margintop={10} size={10} />
            ) : rows ? (
              rows.length > 0 ? (
                <Container style={{ padding: 0, maxWidth: '1440px', marginTop: 10 }}>
                  <Paper sx={{ width: '100%', marginBottom: 2 }}>
                    {rows.map((row, index) => (
                      <Button
                        key={index}
                        onClick={() => {
                          openCompany(row.company_id);
                        }}
                        style={{
                          background: index % 2 === 0 ? palette.background.paper : palette.background.default,
                          padding: '5px 5px',
                          textAlign: 'left',
                          color: '#000',
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={12} sx={{ pb: 1 }}>
                            <Stack direction={'column'}>
                              <Typography
                                variant="caption"
                                color={palette.grey[700]}
                                fontWeight={'700'}
                                size={'10pt'}
                                lineHeight={1.3}
                              >
                                Company Name
                              </Typography>
                              <Typography>{row.company_name}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sx={{ pb: 1 }}>
                            <Stack direction={'column'}>
                              <Typography
                                variant="caption"
                                color={palette.grey[700]}
                                fontWeight={'700'}
                                size={'10pt'}
                                lineHeight={1.3}
                              >
                                Company Address
                              </Typography>
                              <Typography>{row.company_address}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={6} spacing={0} style={{ color: row.company_status === 1 ? 'green' : 'red' }}>
                            <Stack direction={'column'}>
                              <Typography>{row.company_status === 1 ? 'Active' : 'Blocked'}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={6} spacing={0} sx={{ textAlign: 'right' }}>
                            <Stack direction={'row'}>
                              {userToken.role !== 'other' && userToken.role !== 'subadmin' ? (
                                <>
                                  <IconButton
                                    onClick={() =>
                                      handleEdit(
                                        row.company_id,
                                        row.company_name,
                                        row.company_address,
                                        row.company_status
                                      )
                                    }
                                  >
                                    <Edit sx={{ fontSize: '18px' }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleDelete(
                                        row.company_id,
                                        row.company_name,
                                        row.company_address,
                                        row.company_status
                                      )
                                    }
                                  >
                                    <Delete sx={{ fontSize: '18px' }} />
                                  </IconButton>
                                </>
                              ) : null}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Button>
                    ))}
                  </Paper>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    page={page}
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    onPageChange={(e, value) => handlePageChange(e, value)}
                    onRowsPerPageChange={(e) => {
                      handleRowsPerPageChange(e.target.value);
                    }}
                  />
                </Container>
              ) : (
                <NoData />
              )
            ) : (
              <NoData />
            )}
          </Grid>
        ) : (
          <Grid item xs={12} style={{ padding: '10px' }}>
            {showLoader ? (
              <CircularProgress margintop={10} size={10} />
            ) : rows ? (
              rows.length > 0 ? (
                <Container style={{ padding: 0, maxWidth: '1440px', marginTop: 10 }}>
                  <Paper sx={{ width: '100%', marginBottom: 2 }}>
                    {rows.map((row, index) => (
                      <Container
                        maxWidth={false}
                        key={index}
                        onClick={() => {
                          openCompany(row.company_id);
                        }}
                        style={{
                          background: index % 2 === 0 ? palette.background.paper : palette.background.default,
                          padding: '10px 5px',
                          textAlign: 'left',
                          color: '#000',
                          cursor: 'pointer',
                        }}
                      >
                        <Grid container alignItems="center" padding={'5px 10px'} spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Stack direction={'column'}>
                              <Typography
                                variant="caption"
                                color={palette.grey[700]}
                                fontWeight={'700'}
                                size={'10pt'}
                                lineHeight={1.3}
                              >
                                Company Name
                              </Typography>
                              <Typography>{row.company_name}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Stack direction={'column'}>
                              <Typography
                                variant="caption"
                                color={palette.grey[700]}
                                fontWeight={'700'}
                                size={'10pt'}
                                lineHeight={1.3}
                              >
                                Company Address
                              </Typography>
                              <Typography>{row.company_address}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={3} style={{ color: row.company_status === 1 ? 'green' : 'red' }}>
                            <Stack direction={'column'}>
                              <Typography
                                variant="caption"
                                color={palette.grey[700]}
                                fontWeight={'700'}
                                size={'10pt'}
                                lineHeight={1.3}
                              >
                                Status
                              </Typography>
                              <Typography>{row.company_status === 1 ? 'Active' : 'Blocked'}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            {userToken.role !== 'other' && userToken.role !== 'subadmin' ? (
                              <>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEdit(
                                      row.company_id,
                                      row.company_name,
                                      row.company_address,
                                      row.company_status
                                    );
                                  }}
                                >
                                  <Edit sx={{ fontSize: '18px' }} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(row.company_id, row.company_name);
                                  }}
                                >
                                  <Delete sx={{ fontSize: '18px' }} />
                                </IconButton>
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Container>
                    ))}
                  </Paper>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    page={page}
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    onPageChange={(e, value) => handlePageChange(e, value)}
                    onRowsPerPageChange={(e) => {
                      handleRowsPerPageChange(e.target.value);
                    }}
                  />
                </Container>
              ) : (
                <NoData />
              )
            ) : (
              <NoData />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DisplayCompany;
